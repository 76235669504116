import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";

import {
    // HomeOutlined,
    // ContainerOutlined,
    // BarChartOutlined,
    UserOutlined,
    AuditOutlined,
    SettingOutlined,
    DiffOutlined,
    AppstoreAddOutlined,
    ContactsOutlined,
    GlobalOutlined,
    FileSyncOutlined,
    SecurityScanOutlined,
} from '@ant-design/icons';

// 外层
import MainAppLayout from '../layout/mainApp';
import {SubAppLayout} from '../layout';

// 子应用 路由加载
import './resgisterApps';

// 主应用 路由加载
import Login from '../views/user/login/index';
import SetPassword from '../views/user/setPassword/index';
import Register from '../views/user/register/index';
// import MemberList from '../views/pass/organization/member/list';
// import MemberDetail from '../views/pass/organization/member/detail';
// import OrganizationSetting from '../views/pass/organization/setting';

import businessdeliveryPlan from '../views/pass/business/deliveryPlan';
import businesssupplier from '../views/pass/business/supplier';
import businessproduct from '../views/pass/business/product';
import businessTaxArea from '../views/pass/business/taxArea';

import Upload from '../views/linggong/bill/upload';
import BillManage from '../views/linggong/bill/manage';
import BillManageDetail from '../views/linggong/bill/manage/detail/index';
import Customer from '../views/linggong/customer';
import CustomerDetail from '../views/linggong/customer/detail';
import lingGong_Member from '../views/linggong/member';
import lingGong_enterprise from '../views/linggong/enterprise';

import TaxCheck from '../views/linggong/tax/check';
import TaxCheckDetail from '../views/linggong/tax/check/detail'
// 外部路由
const loginRoutes = [
    {
        path: "/user/login",
        component: Login,
        title: '登录'
    },
    {
        path: "/user/setPassword",
        component: SetPassword,
        title: '忘记密码'
    },
    {
        path: "/user/register",
        component: Register,
        title: '注册'
    },
];

/*
* 应用内部路由，menu根据此数组渲染  添加二三级路由需增加 layout 参考layout wageLayout
* 每个系统定义各自路由
*/

const routes_pass = [
    {
        path: '/pass',
        component: MainAppLayout,
        // exact: true,
        routes: [
            // {
            //     path: "/pass/organization",
            //     title: '组织管理',
            //     icon: <UserOutlined/>,
            //     component: SubAppLayout,
            //     routes: [
            //         {
            //             path: "/pass/organization/member/list",
            //             component: MemberList,
            //             icon: <AuditOutlined/>,
            //             title: '花名册'
            //         },
            //         {
            //             path: "/pass/organization/member/detail",
            //             component: MemberDetail,
            //             hidden: true,
            //             title: '详情'
            //         },
            //         {
            //             path: "/pass/organization/setting",
            //             component: OrganizationSetting,
            //             icon: <SettingOutlined/>,
            //             title: '组织管理'
            //         }
            //     ]
            // },
            {
                path: "/pass/business",
                title: '业务配置管理',
                icon: <DiffOutlined />,
                component: SubAppLayout,
                routes: [
                    {
                        path: "/pass/business/supplier",
                        component: businesssupplier,
                        icon: <ContactsOutlined />,
                        title: '供应商管理'
                    },
                    {
                        path: "/pass/business/product",
                        component: businessproduct,
                        icon: <AppstoreAddOutlined />,
                        title: '产品管理'
                    },
                    {
                        path: "/pass/business/taxArea",
                        component: businessTaxArea,
                        icon: <GlobalOutlined />,
                        title: '税源地管理'
                    },
                    {
                        path: "/pass/business/deliveryPlan",
                        component: businessdeliveryPlan,
                        icon: <FileSyncOutlined />,
                        title: '交付方案管理'
                    }
                ]
            },
            // {
            //   path: "/sub_app_1/wage/set",
            //   icon: <BarChartOutlined />,
            //   title: '子应用'
            // }
        ]
    },
]

const routes_lingGong = [
    {
        path: "/lingGong_B",
        title: '组织管理2',
        component: MainAppLayout,
        routes: [
            {
                path: "/lingGong_B/bill/manage",
                component: BillManage,
                icon: <AuditOutlined/>,
                title: '对账单管理'
            },
            {
                path: "/lingGong_B/bill/manage_detail",
                component: BillManageDetail,
                hidden: true,
                title: '对账单管理详情'
            },
            {
                path: "/lingGong_B/member",
                component: lingGong_Member,
                icon: <UserOutlined/>,
                title: '人员管理'
            },
            {
                path: "/lingGong_B/customer",
                component: Customer,
                icon: <SettingOutlined/>,
                title: '客户管理'
            },
            {
                path: "/lingGong_B/customer_detail",
                component: CustomerDetail,
                hidden: true,
                title: '客户管理详情'
            },
        ]
    },
    {
        path: "/lingGong_C",
        title: '业务配置管理',
        component: MainAppLayout,
        routes: [
            {
                path: "/lingGong_C/bill/upload",
                component: Upload,
                icon: <ContactsOutlined />,
                title: '上传对账单'
            },
            {
                path: "/lingGong_C/bill/manage",
                component: BillManage,
                icon: <AppstoreAddOutlined />,
                title: '对账单管理'
            },
            {
                path: "/lingGong_C/bill/manage_detail",
                component: BillManageDetail,
                hidden: true,
                title: '对账单管理详情'
            },
            {
                path: "/lingGong_C/member",
                component: lingGong_Member,
                icon: <GlobalOutlined />,
                title: '人员管理'
            },
            {
                path: "/lingGong_C/enterprise",
                component: lingGong_enterprise,
                icon: <FileSyncOutlined />,
                title: '企业信息'
            }
        ]
    },
    {
        path: "/flexibleLabor",
        title: '灵活用工税局端',
        component: MainAppLayout,
        routes: [
            {
                path: "/flexibleLabor/tax/check",
                component: TaxCheck,
                icon: <SecurityScanOutlined />,
                title: '信息督查'
            },
            {
                path: "/flexibleLabor/tax/check_detail/:id",
                component: TaxCheckDetail,
                hidden: true,
                title: '信息督查'
            }
        ]
    },
]

//@ts-ignore
function RouteWithSubRoutes(route: any) {
    // console.log(route)
    // if(route.routes) {RouteWithSubRoutes(route.routes)}
    return (
        <Route
            key={route.path}
            path={route.path}
            exact={route.exact || false}
            hiddle={route.hidden}
            component={(props: any) => <route.component {...props} routes={route.routes}/>}
        />
    );
}

export const routesArr = routes_pass;

const allRoutes = [...loginRoutes, ...routes_pass, ...routes_lingGong];
// console.log(allRoutes)

const list = allRoutes.map((item, i) => {
    return RouteWithSubRoutes(item)
    // return <RouteWithSubRoutes key={i} {...item} />
})
// console.log(list)
export default () => (
    <Switch>
        <Route exact path="/" render={() => (
            <Redirect to="/user/login" />
        )} />
        {/*<Route path={'/lingGong_C/member'}*/}
               {/*component={lingGong_Member}*/}
        {/*/>*/}

            {list}
            {/*<Route component={() => <div>找不到</div>}/>*/}
        </Switch>
    )
