import React, {useEffect, useState} from "react";
import {
    Button, Checkbox, Drawer,
    Form, Input,
    Modal,
    Select,
    Table,
    Radio, message
} from 'antd';

import {TreeDepartment} from "@/components/select";
import {Dispatch} from "redux";
import {api_index, mock_table} from "@/store/model/reducers/index";
import {connect} from "react-redux";
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';
import InputComponent from '@/components/inputBox/index'

import TaxCom from '@/components/taxSelect'

import {RouteComponentProps} from "react-router";
import {ajax} from "@/store/midPromise";
import {instanceOf} from "prop-types";

const { confirm } = Modal;

interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    typeArr: any,
    get_mock: () => void,
    api_all: (name:string, data: API.InterfaceData) => void,
}

// console.log(InputComponent)

// const InputDot = InputComponent(Input)

const { Search } = Input;
const { Option } = Select;

const TaxArea:React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;

    const [resData, setresData] = useState({total: '', page: [], loading: true})
    const [opeData, setopeData] = useState({flag: false, title: '', id: ''})
    const [ajaxData, setAjaxData] = useState({
        taxSourceStatus: '',
        keyword: '',
        page: 1,
        rows: 10
    })

    const [formSet] = Form.useForm();

    const mockTaxList = [
        {
            label: '企业增值税附加税之城市维护建设税税率',
            taxField: 'companyConstruction',
            taxType: 0,
            taxValue: ''
        },
        {
            label: '企业增值税附加税之教育费附加税率',
            taxField: 'companyLearn',
            taxType: 0,
            taxValue: ''
        },
        {
            label: '企业增值税附加税之地方教育费附加税率',
            taxField: 'companyLearnLocal',
            taxType: 0,
            taxValue: ''
        },
        {
            label: '个人经营所得增值税税率',
            taxField: 'personAddTax',
            taxType: 0,
            taxValue: ''
        },
        {
            label: '个人经营所得增值税附加税之城市维护建设税税率',
            taxField: 'personConstruction',
            taxType: 0,
            taxValue: ''
        },
        {
            label: '个人经营所得增值税附加税之教育费附加税率',
            taxField: 'personLearn',
            taxType: 0,
            taxValue: ''
        },
        {
            label: '个人经营所得增值税附加税之地方教育费附加税率',
            taxField: 'personLearnLocal',
            taxType: 0,
            taxValue: ''
        },
        {
            label: '个税税率',
            taxField: 'taxRate',
            taxType: 0,
            taxValue: ''
        }
    ]

    const resetform = () => {
        setTaxList([...mockTaxList])
        formSet.resetFields()
    }
    const add = () => {
        resetform()
        setopeData({...opeData, ...{flag: true, title: '新增税源地'}})
        formSet.setFieldsValue( {
            companyConstruction: 10,
            companyConstructionValue: '',
            companyLearn: 10,
            companyLearnValue: '',
            companyLearnLocal: 10,
            companyLearnLocalValue: '',
            personAddTax: 10,
            personAddTaxValue: '',
            personConstruction: 10,
            personConstructionValue: '',
            personLearn: 10,
            personLearnValue: '',
            personLearnLocal: 10,
            personLearnLocalValue: '',
            taxRate: 10,
            taxRateValue: '',
        });
    }
    const edit = (data: any) => {
        resetform()
        setopeData({flag: true, title: '编辑', id: data.id})
        const dd = {
            ...data,
            ...{
                companyConstruction: data.companyConstruction.type,
                companyConstructionValue: data.companyConstruction.value,
                companyLearn: data.companyLearn.type,
                companyLearnValue: data.companyLearn.value,
                companyLearnLocal: data.companyLearnLocal.type,
                companyLearnLocalValue: data.companyLearnLocal.value,
                personAddTax: data.personAddTax.type,
                personAddTaxValue: data.personAddTax.value,
                personConstruction: data.personConstruction.type,
                personConstructionValue: data.personConstruction.value,
                personLearn: data.personLearn.type,
                personLearnValue: data.personLearn.value,
                personLearnLocal: data.personLearnLocal.type,
                personLearnLocalValue: data.personLearnLocal.value,
                taxRate: data.taxRate.type,
                taxRateValue: data.taxRate.value,
            }
        }
        const list = taxList.map((item:any) => {
            item.taxType = data[item.taxField]['type']
            item.taxValue = data[item.taxField]['value']
            return item
        })
        setTaxList([...list])
        formSet.setFieldsValue( dd );
    }

    const formPush = (formData: any) => {
        const list = taxList.map((item:any) => {
            item.taxType = formData[item.taxField]
            item.taxValue = formData[item.taxField + 'Value']
            return item
        })
        // console.log(list)
        const data = {...formData, ...{taxExtendList: [...list]}}

        if(opeData.title === '新增税源地') {
            ajax.post(`/api/Non-eeds/admin/business/tax`, data).then((res: any) => {
                if(res && res.code === 200) {
                    setopeData({...opeData, ...{flag: false, title: ''}})
                    message.success('成功')
                    init()
                }
            })
        }
        if (opeData.title === '编辑') {
            ajax.put(`/api/Non-eeds/admin/business/tax/${opeData.id}`, data).then((res: any) => {
                if(res && res.code === 200) {
                    setopeData({...opeData, ...{flag: false, title: ''}})
                    message.success('成功')
                    init()
                }
            })
        }
    }

    const changeStatus = (data: any) => {
        const text = windowObj.GLOBAL_LIBS.getStatus(data.taxSourcesStatus, true, true)
        const status = windowObj.GLOBAL_LIBS.getStatus(data.taxSourcesStatus, false, true)
        confirm({
            title: `确认要对该税源地进行【${text}】吗？`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk() {
                ajax.put(`/api/Non-eeds/admin/business/tax/${data.id}/status`, {status}).then((res: any) => {
                    if(res && res.code === 200) {
                        setopeData({...opeData, ...{flag: false, title: ''}})
                        message.success('成功')
                        init()
                    }
                })
                // props.api_all('put_pass_tax', {api: `/api/tax/${data.id}`, success: () => init() })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const init = () => {
        ajax.get(`/api/Non-eeds/admin/business/tax`, {params: ajaxData}).then((res: any) => {
            setresData({...resData, ...{loading: false}})
            if(res && res.code === 200) setresData({...res.data})
        })
    }

    useEffect (() => {
        init()
    }, [ajaxData])

    const mapTaxList = (list:any[]) => {
        // console.log(list)
        return list.map((item) => {
            return <div>{item.first + item.buttons + '收入额' + item.buttonb + (Number(item.last)>0 ? item.last : '') + ' ' + item.tax + '%'}</div>
        })
    }

    const columns = [
        {
            title: '税源地名称',
            align: 'center' as 'center',
            dataIndex: 'taxSourceName',
            width: 220,
        },
        {
            title: '企业增值税税率',
            align: 'center' as 'center',
            dataIndex: 'companyUpTax',
            width: 100,
        },
        {
            title: '企业增值税附加税',
            key: 'c1',
            children: [
                {
                    title: '城市维护建设税税率',
                    align: 'center' as 'center',
                    dataIndex: 'companyConstruction',
                    width: 180,
                    render: (data:any) => <div>{data.type===10? data.value: mapTaxList(JSON.parse(data.value))}</div>
                },
                {
                    title: '教育费附加税率',
                    dataIndex: 'companyLearn',
                    align: 'center' as 'center',
                    width: 180,
                    render: (data:any) => <div>{data.type===10? data.value: mapTaxList(JSON.parse(data.value))}</div>
                },
                {
                    title: '地方教育费附加税率',
                    dataIndex: 'companyLearnLocal',
                    align: 'center' as 'center',
                    width: 180,
                    render: (data:any) => <div>{data.type===10? data.value: mapTaxList(JSON.parse(data.value))}</div>
                },
            ]
        },
        {
            title: '个人经营所得增值税税率',
            dataIndex: 'personAddTax',
            align: 'center' as 'center',
            width: 140,
            render: (data:any) => <div>{data.type===10? data.value: mapTaxList(JSON.parse(data.value))}</div>
        },
        {
            title: '个人经营所得增值税附加税',
            children: [
                {
                    title: '城市维护建设税税率',
                    dataIndex: 'personConstruction',
                    align: 'center' as 'center',
                    width: 180,
                    render: (data:any) => <div>{data.type===10? data.value: mapTaxList(JSON.parse(data.value))}</div>
                },
                {
                    title: '教育费附加税率',
                    dataIndex: 'personLearn',
                    align: 'center' as 'center',
                    width: 180,
                    render: (data:any) => <div>{data.type===10? data.value: mapTaxList(JSON.parse(data.value))}</div>
                },
                {
                    title: '地方教育费附加税率',
                    dataIndex: 'personLearnLocal',
                    align: 'center' as 'center',
                    width: 180,
                    render: (data:any) => <div>{data.type===10? data.value: mapTaxList(JSON.parse(data.value))}</div>
                },
            ]
        },
        {
            title: '个税税率',
            dataIndex: 'taxRate',
            width: 180,
            render: (data:any) => {
                return <div>{data.type===10? data.value: mapTaxList(JSON.parse(data.value))}</div>
            }
        },
        {
            title: '状态',
            dataIndex: 'taxSourcesStatus',
            width: 100,
            render: (data: any) => <div>{data===10? "已启用": "已停用"}</div>
        },
        {
            title: '操作',
            width: 180,
            fixed: "right" as "right",
            render: (data: any) => <>
                <Button type={'link'} size={'small'} onClick={() => edit(data)}>编辑</Button>
                <Button type={'link'} size={'small'} onClick={() =>  changeStatus(data) }>{windowObj.GLOBAL_LIBS.getStatus(data.taxSourcesStatus, true, true)} </Button>
            </>
        },
    ];

    const newList = JSON.parse(JSON.stringify(mockTaxList))
    const [taxList, setTaxList] = useState(newList)

    const layout = {
        labelCol: { span: 8},
        wrapperCol: { span: 16 },
        onFinish: formPush,
        form: formSet
    }
    const tableOptions = {
        rowKey: 'id',
        bordered: true,
        scroll:{x: 'max-content', y: 540},
        loading: resData.loading,
        columns: columns,
        dataSource: resData.page,
        onChange: (data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }

    return (
        <div className="content-page">

            <div className='c_flex c_flex_up c_flex_rl' style={{marginBottom: 20}}>
                <div>
                    <Search placeholder="输入税源地名称搜索" style={{width: 320}} onSearch={value => setAjaxData({...ajaxData, ...{keyword: value, page: 1}})} enterButton />
                    <span className='c_ml_20'>状态:</span>
                    <Select defaultValue="0" style={{ width: 120, marginLeft: 20 }}
                            onChange={(value: string) => setAjaxData({...ajaxData, ...{taxSourceStatus: value !=='0'? value: '', page: 1}})}>
                        <Option value={'0'} >全部</Option>
                        {
                            props.typeArr.taxArea_status.map((item: any) => {
                                return  <Option value={item.value} key={item.value} >{item.name}</Option>
                            })
                        }
                    </Select>
                </div>

                <Button type={'primary'} onClick={add}>新增</Button>
            </div>

            <Table
                {...tableOptions}
                columns={columns}
                dataSource={resData.page}
                onChange={(data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}})}
                pagination={{total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}}
            />

            <Drawer
                width={1040}
                title={opeData.title}
                placement="right"
                closable={true}
                onClose={() => {setopeData({...opeData, ...{flag: false, title: ''}})}}
                visible={opeData.flag}
                destroyOnClose={true}
            >
                <Form {...layout}>
                    <Form.Item
                        label="税源地名称"
                        name="taxSourceName"
                        rules={[{ required: true}]}
                    >
                        <Input placeholder='请输入'  />
                    </Form.Item>
                    <Form.Item
                        label="企业增值税税率"
                        name="companyUpTax"
                        rules={[{ required: true}]}
                    >
                        <Input placeholder='请输入' suffix={'%'} />
                    </Form.Item>

                    {
                        taxList.map((item: any) => <TaxCom label={item.label} name={item.taxField}
                                                           getForm={formSet}
                                                           getType={item.taxType}
                                                           getValue={item.taxValue} offset={8} />)
                    }

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
}

const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig,
        pageSizeOptions: state.golbal.pageSizeOptions,
        member_list: state.index.member_list,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get_mock: () => {
            dispatch(mock_table())
        },
        api_all: (name:string, data: API.InterfaceData, ) => {
            dispatch(api_index({...data})(name))
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(TaxArea);

