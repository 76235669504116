// const windowObj = global.windows || window;
// const APINAME = windowObj.localStorage.getItem('apiName')

export const DataTitle = {
    detail_title: [
        {
            title: '姓名',
            dataIndex: 'bdName',
            width: 160,
        },
        {
            title: '身份证号码',
            dataIndex: 'bdEid',
            width: 160,
        },
        {
            title: '手机号码',
            dataIndex: 'bdPhone',
            width: 160,
        },
        {
            title: '应用的产品',
            dataIndex: 'relationPro',
            width: 160,
        },
        {
            title: '计算方式',
            dataIndex: 'countMethod',
            width: 160,
            render: (data:any) => data === 10? '已知实发计算应发': '已知应发计算实发'
        },
        {
            title: '应发总额',
            dataIndex: 'termMoney',
            width: 160,
        },
        {
            title: '实发金额',
            dataIndex: 'trueMoney',
            width: 160,
        },
        {
            title: '个人经营所得增值税税率',
            dataIndex: 'billDetailOther',
            width: 160,
            render: (data:any) => data.pupValue
        },
        {
            title: '个人经营所得增值税',
            dataIndex: 'billDetailOther',
            width: 160,
            render: (data:any) => data.pupRate
        },
        {
            title: '个人经营所得增值税附加税税率',
            dataIndex: 'billDetailOther',
            width: 160,
            render: (data:any) => data.pupsValue
        },
        {
            title: '个人经营所得增值税附加税',
            dataIndex: 'billDetailOther',
            width: 160,
            render: (data:any) => data.pupsRate
        },
        {
            title: '个人所得税税率',
            dataIndex: 'billDetailOther',
            width: 160,
            render: (data:any) => data.perValue
        },
        { // 外边
            title: '个人所得税',
            dataIndex: 'perGetTax',
            width: 160,
        },
        {
            title: '服务费率',
            dataIndex: 'billDetailOther',
            width: 160,
            render: (data:any) => data.serverFeeRate
        },
        { //
            title: '服务费',
            dataIndex: 'serverFee',
            width: 160,
        },
        {
            title: '企业增值税税率',
            dataIndex: 'billDetailOther',
            width: 160,
            render: (data:any) => data.cupValue
        },
        {
            title: '企业增值税',
            dataIndex: 'billDetailOther',
            width: 160,
            render: (data:any) => data.cupRate
        },
        {
            title: '企业增值税附加税税率',
            dataIndex: 'billDetailOther',
            width: 160,
            render: (data:any) => data.cupsRate
        },
        {
            title: '企业增值税附加税',
            dataIndex: 'billDetailOther',
            width: 160,
            render: (data:any) => data.cupsValue
        },
        {
            title: '实发金额',
            dataIndex: 'trueMoney',
            width: 160,
        },
        {
            title: '银行卡账号',
            dataIndex: 'bankNum',
            width: 160,
        },
        {
            title: '开户行',
            dataIndex: 'bank',
            width: 160,
        },
        {
            title: '到款总额',
            dataIndex: 'bdTomoney',
            width: 160,
        },
        {
            title: '异常信息',
            dataIndex: 'unusualMes',
            width: 160,
            render: (data: any) => JSON.parse(data).map((item:any) => item + '，' )
        },
    ],
    detail_title_customer: [
        {
            title: '姓名',
            dataIndex: 'bdName',
            width: 160,
        },
        {
            title: '身份证号码',
            dataIndex: 'bdEid',
            width: 160,
        },
        {
            title: '手机号码',
            dataIndex: 'bdPhone',
            width: 160,
        },
        {
            title: '应发总额',
            dataIndex: 'termMoney',
            width: 160,
        },
        {
            title: '个人增值税',
            dataIndex: 'billDetailOther',
            width: 160,
            render: (data:any) => (Number(data.pupRate)*100 + Number(data.pupsRate)*100)/ 100
        },
        {
            title: '个人所得税',
            dataIndex: 'perGetTax',
            width: 160
        },
        {
            title: '服务费率',
            dataIndex: 'billDetailOther',
            width: 160,
            render: (data:any) => data.serverFeeRate
        },
        { //
            title: '服务费',
            dataIndex: 'serverFee',
            width: 160,
        },
        {
            title: '企业增值税',
            dataIndex: 'billDetailOther',
            width: 160,
            render: (data:any) => ((Number(data.cupRate)*100 + Number(data.cupsValue)*100)) / 100
        },
        {
            title: '实发金额',
            dataIndex: 'trueMoney',
            width: 160,
        },
        {
            title: '银行卡账号',
            dataIndex: 'bankNum',
            width: 160,
        },
        {
            title: '开户行',
            dataIndex: 'bank',
            width: 160,
        },
        {
            title: '到款总额',
            dataIndex: 'bdTomoney',
            width: 160,
        },
        {
            title: '异常信息',
            dataIndex: 'unusualMes',
            width: 160,
            render: (data: any) => JSON.parse(data).map((item:any) => item + '，' )
        },
    ],
    total_title_customer: [
        {
            title: '应发总额',
            dataIndex: 'sumShould',
            width: 160,
        },
        {
            title: '个人增值税',
            width: 160,
            render: (data:any) => ((Number(data.sumPerGet)*100 + Number(data.sumPerAdd)*100)) / 100
        },
        {
            title: '个人所得税',
            dataIndex: 'sumPer',
            width: 160,
        },
        {
            title: '服务费',
            dataIndex: 'lastServerFee',
            width: 160
        },
        {
            title: '企业增值税',
            width: 160,
            render: (data:any) => ((Number(data.sumComGet)*100 + Number(data.sumComAdd)*100)) / 100
        },
        {
            title: '实发金额',
            dataIndex: 'sumReal',
            width: 160,
        },
        {
            title: '到款总额',
            dataIndex: 'sumComec',
            width: 160,
        }
    ],
    detail_title_customer_error: [
        {
            title: '姓名',
            dataIndex: 'bdName',
            width: 160,
        },
        {
            title: '身份证号码',
            dataIndex: 'bdEid',
            width: 160,
        },
        {
            title: '手机号码',
            dataIndex: 'bdPhone',
            width: 160,
        },
        {
            title: '应用产品',
            dataIndex: 'relationPro',
            width: 160,
        },
        {
            title: '应发/实发总额',
            dataIndex: 'termMoney',
            width: 160,
        },
        {
            title: '银行卡账号',
            dataIndex: 'bankNum',
            width: 160,
        },
        {
            title: '开户行',
            dataIndex: 'bank',
            width: 160,
        },
    ]
}
