//
const mouthL = []
for (let i=0; i<28; i++){
    mouthL.push({
        value: i+1,
        name: i+1
    })
}

const data = {
    // 性别
    sex: [
        {
            name: '男',
            value: '1'
        },
        {
            name: '女',
            value: '2'
        }
    ],
    // 业务管理 产品类型
    product_type: [
        {
            name: '雇用经济',
            value: '1'
        },
        {
            name: '非雇用经济',
            value: '2'
        },
        {
            name: 'SaaS售卖',
            value: '3'
        }
    ],
    // 业务管理 产品状态
    product_status: [
        {
            name: '已启用',
            value: '10'
        },
        {
            name: '已停用',
            value: '20'
        }
    ],
    // 业务管理 供应商性质
    supplier_nature: [
        {
            name: '内部',
            value: '0'
        },
        {
            name: '外部',
            value: '10'
        }
    ],
    // 业务管理 供应商状态
    supplier_status: [
        {
            name: '已启用',
            value: '10'
        },
        {
            name: '已停用',
            value: '20'
        }
    ],
    // 业务管理 税源地状态
    taxArea_status: [
        {
            name: '已启用',
            value: '10'
        },
        {
            name: '已停用',
            value: '20'
        }
    ],
    // 业务管理 交付方案状态
    deliveryPlan_status: [
        {
            name: '已启用',
            value: '10'
        },
        {
            name: '已停用',
            value: '20'
        }
    ],
    // 业务管理 交付方案状态
    kuaiDi: [
        {
            name: '顺丰快递',
            value: '1'
        },
        {
            name: '中通快递',
            value: '2'
        },
        {
            name: 'EMS',
            value: '2'
        },
        {
            name: '圆通快递',
            value: '2'
        },
        {
            name: '韵达快递',
            value: '2'
        },
        {
            name: '申通快递',
            value: '2'
        },
        {
            name: '京东快递',
            value: '2'
        }
    ],
    // 1-28
    mouthList: mouthL
}

export default data

