import React, {useEffect, useState} from "react";
import {
    useParams
} from 'react-router-dom'
import {
    Button, Checkbox, Drawer,
    Form, Input,
    Modal,
    Select,
    Table,
    Radio, DatePicker, Steps,
    Upload, Result, message, Spin
} from 'antd';

import {TreeDepartment} from "@/components/select";
import {Dispatch} from "redux";
import {api_index, mock_table} from "@/store/model/reducers/index";
import {connect} from "react-redux";
import {
    CloudUploadOutlined,
    CloudSyncOutlined,
    LoadingOutlined,
    SmileOutlined,
    UploadOutlined
} from '@ant-design/icons';
import InputComponent from '@/components/inputBox/index'
import moment from 'moment';

import {RouteComponentProps} from "react-router";
import {ajax} from "@/store/midPromise";
// import {file} from "@babel/types";
// import data from "@/statics/js/config/config-data";
// import * as GLOBAL_LIBS from "@/statics/js/libs";
import {RangePickerProps} from 'antd/lib/date-picker'
import { Moment } from 'moment';

const {confirm} = Modal;

interface Interfaceprops extends RouteComponentProps {
    pageSizeOptions: string[],
    typeArr: any,
    get_mock: () => void,
    get_mock2: (data: API.InterfaceData) => void,
    LIBS: any
}

const {Search} = Input;
const {Step} = Steps;
const {RangePicker} = DatePicker;
const {Dragger} = Upload;

const dateFormat = 'YYYY-MM-DD';
const UploadPage: React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')
    const personNum = windowObj.GLOBAL_LIBS.getQuery(props.location.search, 'personNum')
    const payNum = windowObj.GLOBAL_LIBS.getQuery(props.location.search, 'payNum')
    const billID = windowObj.GLOBAL_LIBS.getQuery(props.location.search, 'billID')

    const [uploadData, setUploadData] = useState({})
    const [ajaxData, setAjaxData] = useState({page: 1, rows: 10})
    const [resData, setresData] = useState({total: 0, page: [], loading: true, failCount: 0, count: 0, newCount: 0})
    const [addvisible, setaddvisible] = useState(false)
    const [disabledNext, setDisabledNext] = useState(false)
    const [step, setStep] = useState(0)
    const [fileList, setfileList] = useState([])
    const [uploadTime, setuploadTime] = useState<Array<string| null>>([])

    const [resDataAdd, setresDataAdd] = useState({total: 0, page: [], loading: true})
    const [ajaxDataAdd, setAjaxDataAdd] = useState({page: 1, rows: 10})

    const [loadingAll, setLoadingAll] = useState([false])
    const columnsAdd = [
        {
            title: '姓名',
            dataIndex: 'pname',
            width: 120,
        },
        {
            title: '手机号',
            dataIndex: 'pphone',
            width: 140,
        },
        {
            title: '身份证号码',
            dataIndex: 'idCard',
            width: 160,
        },
        {
            title: '银行卡账号',
            dataIndex: 'pbankcard',
            width: 140,
        },
        {
            title: '开户行',
            dataIndex: 'pbank',
            width: 140,
        }
    ]

    let UpTime = ['', '']



    const goDetail = (data: any) => {
        console.log(data)
    }
    const seeAddData = () => {
        setaddvisible(true)
        addDataInit()
    }
    const addDataInit = () => {
        ajax.get(`/api/Non-eeds/check/customer/getAttention`, {params: {...ajaxDataAdd}}).then((res: any) => {
            setresDataAdd({...resData, ...{loading: false}})
            if(res && res.code === 200) setresDataAdd({...res.data})
        })
    }
    const handleChange = (info: any) => {
        if(!uploadTime[0] || !uploadTime[1]){
            message.error('请选择对账单周期')
            return
        }
        const {file} = info;
        // console.log(file)
        setTimeout(() => {
            // @ts-ignore
            setfileList([file]);
            upload(file)
        }, 200)

    }
    const getTime = (data:any) => {
        if(!data) {
            setuploadTime([null, null])
            return
        }
        setuploadTime([moment(data[0]).format('YYYY-MM-DD'), moment(data[1]).format('YYYY-MM-DD')])
        UpTime=[moment(data[0]).format('YYYY-MM-DD'), moment(data[1]).format('YYYY-MM-DD')]
        // console.log(data, uploadTime, UpTime)
    }
    const pushResult = () => {
        setStep(0)
        ajax.post(`/api/Non-eeds/check/customer/count`, {}).then((res: any) => {
            if(res && res.code === 200) {
                setStep(4)
                ajax.get(`/api/Non-eeds/check/customer/getCard/${res.data.billBatchId}`, {})
                props.history.push(`/lingGong_C/bill/upload?step=4&personNum=${personNum}&payNum=${payNum}&billID=${res.data.billBatchId}`)
            }
        })
    }
    const upload = (file:File) => {
        setStep(0)
        getTime(uploadTime)
        const formData = new FormData()
        formData.append('file', file)
        formData.append('planTerm', JSON.stringify(UpTime))
        ajax.post(`/api/Non-eeds/aliyunOss/customer/upload`, formData).then((res: any) => {
            if(res && res.code === 200) {
                setStep(2)
                const res1 = res.data
                props.history.push(`/lingGong_C/bill/upload?step=2`)
            } else {
                setStep(1 )
            }
        })
    }
    const downloadBill = () => {
        setStep(0)
        windowObj.GLOBAL_LIBS.downloadFile('billUpload', () => {
            setStep(1)
        })
    }

    const init2 = () => {
        ajax.post(`/api/Non-eeds/check/customer/billList`, {...ajaxData}).then((res: any) => {
            setresData({...resData, ...{loading: false}})
            if(res && res.code === 200) setresData({...res.data})
            else setDisabledNext(true)
        })
    }
    const sureBill = () =>{
        const arr = [...loadingAll]
        arr[0] = true
        setLoadingAll(arr)
        ajax.post(`/api/Non-eeds/check/customer/commit`, {}).then((res: any) => {
            arr[0] = false
            setLoadingAll(arr)
            if(res && res.code === 200) {
                setStep(3)
                props.history.push(`/lingGong_C/bill/upload?step=3&personNum=${res.data.bill_person}&payNum=${res.data.btotal_money}`)
            }
        })
    }
    const init1 = () => {
        ajax.get(`/api/Non-eeds/reception/customer/getPlanTerm`).then((res: any) => {
            if(res && res.code === 200 && res.data) {
                const data = res.data.split(',')
                // console.log(data)
                setuploadTime(data)
            }
        })
    }
    useEffect(() => {
        console.log(step)
        if(step === 1) init1()
        if(step === 2) init2()
    }, [ajaxData, step ])


    useEffect(() => {
        let stepNow = windowObj.GLOBAL_LIBS.getQuery(props.location.search, 'step')
        stepNow = Number(stepNow) || 1
        setStep(stepNow)
        // console.log(step, '2222222')
    }, [])

    useEffect(() => {
        if(addvisible) addDataInit()
    }, [ajaxDataAdd])

    const columns_ok = [
        {
            title: '姓名',
            dataIndex: 'pname',
            width: 120,
        },
        {
            title: '手机号',
            dataIndex: 'pphone',
            width: 140,
        },
        {
            title: '身份证号码',
            dataIndex: 'idCard',
            width: 160,
        },
        {
            title: '实发/应发金额',
            dataIndex: 'money',
            width: 140,
        },
        {
            title: '应用产品',
            dataIndex: 'useProduct',
            width: 160,
        },
        {
            title: '银行卡号',
            dataIndex: 'pbankcard',
            width: 180,
        },
        {
            title: '开户行',
            dataIndex: 'pbank',
            width: 120,
        }
    ];
    const columns_errors = [
        {
            title: '行号',
            dataIndex: 'line',
            width: 100,
        },
        {
            title: '姓名',
            dataIndex: 'name',
            width: 120,
        },
        {
            title: '身份证号码',
            dataIndex: 'eid',
            width: 200,
        },
        {
            title: '出错原因',
            dataIndex: 'mesList',
            width: 200,
            render: (data:any) => data && data.map((item: string, index:number) => <div key={index}>{item}</div> )
        },
        {
            title: '建议操作',
            dataIndex: 'sugList',
            width: 200,
            render: (data:any) => data && data.map((item: string, index:number) => <div key={index}>{item}</div> )
        }
    ];

    const uploads = {
        name: 'file',
        fileList: fileList,
        onRemove: () => {
            setfileList([]);
        }
    }

    const tableOptions = {
        rowKey: 'id',
        scroll:{x: 'max-content', y: 540},
        loading: resData.loading,
        columns: resData.failCount > 0 ?columns_errors: columns_ok,
        dataSource: resData.page,
        onChange: (data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }
    const tableOptions2 = {
        rowKey: 'id',
        scroll:{x: 'max-content', y: 540},
        loading: resDataAdd.loading,
        columns: columnsAdd,
        dataSource: resDataAdd.page,
        onChange: (data:any) => setAjaxDataAdd({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resDataAdd.total), pageSize: ajaxDataAdd.rows, current: ajaxDataAdd.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }

    // @ts-ignore
    return (
        <div className="content-page">

            <div style={{padding: '20px 60px 100px'}}>
                <Steps>
                    <Step status={step === 1 ? "process" : "finish"} title="上传"
                          icon={step === 1 ? <LoadingOutlined/> : <CloudUploadOutlined/>}/>
                    <Step status={step === 2 ? "process" : step === 1 ? "wait" : "finish"} title="校验"
                          icon={step === 2 ? <LoadingOutlined/> :<CloudSyncOutlined/>}/>
                    <Step status={step < 3 ? "wait" : "finish"} title="提交" icon={<SmileOutlined/>}/>
                </Steps>
            </div>
            {
                step === 0 &&
                <div style={{width: '100%', textAlign: 'center', padding: '100px 0'}}><Spin tip={"请稍后,加载中..."} /></div>
            }

            {
                step === 1 &&
                <div style={{padding: '0 60px'}}>
                    <div style={{marginBottom: 20}}>
                        <span>对账单所在账期：</span>
                        <RangePicker value={[uploadTime[0]?moment(uploadTime[0]): null, uploadTime[1]?moment(uploadTime[1]): null]} onChange={getTime} />
                    </div>
                    <Dragger {...uploads} customRequest={handleChange}>
                        <p className="ant-upload-drag-icon"><UploadOutlined/></p>
                        <p className="ant-upload-text">点击或拖拽进行上传</p>
                        {/*<p className="ant-upload-hint">支持JPG、PNG、PDF文件</p>*/}
                    </Dragger>
                    <div style={{marginTop: 20}}>上传模板<a type='primary' onClick={downloadBill}>【下载对账单模板】</a></div>
                </div>
            }

            {
                step === 2 &&
                <div style={{padding: '0 60px'}}>
                    <div className='c_flex c_flex_up c_flex_rl' style={{padding: '0 60px 0px 0'}}>
                        <span>本次共导入：{resData.total}人</span>
                        {
                            resData.failCount > 0 &&
                            <>
                                <span>异常：{resData.failCount} 人</span>
                                <span>以下列表为 <span className='c_error'>失败项</span> ，请修正表格信息后重新上传。</span>
                            </>
                        }
                    </div>
                    {
                        resData.newCount > 0 &&
                        <span>注意：本次导入新增人员 {resData.newCount} 名，系统将默认添加至人员管理；<Button type={'link'} onClick={seeAddData}>点击查看新增人员</Button></span>
                    }
                    <div style={{height: 40}}></div>
                    <Table {...tableOptions} />

                    <div className='c_flex_cen c_flex c_mt_20'>
                        <Button className='c_mr_10' onClick={() => {setStep(1); props.history.push(`/lingGong_C/bill/upload?step=1`)}}>重新上传</Button>
                        <Button type={'primary'} disabled={(resData.failCount > 0 || disabledNext ) ? true: false}
                                loading={loadingAll[0]}
                                onClick={ sureBill}>下一步</Button>
                    </div>
                </div>
            }

            {
                step === 3 &&
                <Result
                    title={`本次共提交 ${personNum} 人，总金额 ${payNum} 元。`}
                    subTitle="点击“提交”后对账单将发送至管理后台进行审核，确认提交？"
                    extra={<Button type="primary" key="console" onClick={pushResult}>提交</Button>}
                />
            }
            {
                step === 4 &&
                <Result
                    status="success"
                    title={`本次共提交 ${personNum} 人，总金额 ${payNum} 元。`}
                    subTitle="对账单应付金额详情请在对账单管理中查看"
                    extra={<Button type="primary" key="console" onClick={() => props.history.push(`/lingGong_C/bill/manage_detail?id=${billID}`)}>完成</Button>}
                />
            }

            <Modal title="新增人员" visible={addvisible} width={1000}
                onOk={() => setaddvisible(false)}
                onCancel={() => setaddvisible(false)}>
                <Table {...tableOptions2} />
            </Modal>
        </div>
    );
}

const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig,
        pageSizeOptions: state.golbal.pageSizeOptions,
        member_list: state.index.member_list,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get_mock: () => {
            dispatch(mock_table())
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(UploadPage)
