import React, {useEffect, useState} from "react";
import {
    Button, Checkbox, Drawer,
    Form, Input,
    Modal,
    Select,
    Table,
    Radio, DatePicker, message
} from 'antd';

import {TreeDepartment} from "@/components/select";
import {Dispatch} from "redux";
import {api_index} from "@/store/model/reducers/index";

import {connect} from "react-redux";
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';
// import InputComponent from '@/components/inputBox/index'

import {RouteComponentProps} from "react-router";
import ImportsBox from "@/components/importBox";
import {ajax} from "@/store/midPromise";

const { confirm } = Modal;


// console.log(APINAME)
interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    typeArr: any,
    get_mock: () => void,
    api_all: (name:string, data: API.InterfaceData) => void,
}

const { Search } = Input;
const {Option} = Select;


const Member:React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')

    const [resData, setresData] = useState({total: '', page: [], loading: true})
    const [customerList, setCustomerList] = useState({total: '', page: []})

    const [opeData, setopeData] = useState({flag: false, title: '', id: ''})
    const [putOnoff, setputOnoff] = useState(0)

    const [ajaxData, setAjaxData] = useState({
        keyword: '',
        page: 1,
        rows: 10
    })
    const [formSet] = Form.useForm();

    const add = () => {
        setopeData({...opeData, ...{title: '新增',flag: true}})
        resetForm()
    }
    const resetForm = () => {
        formSet.setFieldsValue({
            "customerName": '',
            "pname": '',
            "pphone": '',
            "idCard": "",
            "pbankcard": "",
            "pbank": ""
        })
    }
    const edit = (data: any) => {
        resetForm()
        console.log(data)
        setopeData({...opeData, ...{flag: true, title: '编辑', id: data.id}})
        formSet.setFieldsValue({...data})
    }
    const modifyMember = () => {

    }

    const formPush = (data: any) => {
        if(opeData.title === '新增') {

            ajax.post(`/api/Non-eeds/reception/${APINAME}/person`, data).then((res: any) => {
                // console.log(res)
                if(res && res.code === 200) {
                    setopeData({...opeData, ...{flag: false, title: ''}})
                    message.success('成功')
                    init()
                }
            })
        }
        if (opeData.title === '编辑') {
            ajax.put(`/api/Non-eeds/reception/${APINAME}/person/${opeData.id}`, data).then((res: any) => {
                // console.log(res)
                if(res && res.code === 200) {
                    setopeData({...opeData, ...{flag: false, title: ''}})
                    message.success('成功')
                    init()
                }
            })
        }

    }

    const init = () => {
        ajax.get(`/api/Non-eeds/reception/${APINAME}/person`, {params: ajaxData}).then((res: any) => {
            setresData({...resData, ...{loading: false}})
            if(res && res.code === 200) setresData({...res.data})
        })
        if (APINAME === 'company'){
            ajax.get(`/api/Non-eeds/reception/${APINAME}/customer`, {params: {rows: 500}}).then((res: any) => {
                if(res && res.code === 200) setCustomerList({...res.data})
            })
        }
    }

    useEffect (() => {
        init()
    }, [ajaxData])

    const columns_B = [
        {
            title: '签约客户名称',
            dataIndex: 'customerName',
            width: 160,
        },
        {
            title: '姓名',
            dataIndex: 'pname',
            width: 100,
        },
        {
            title: '手机号码',
            dataIndex: 'pphone',
            width: 120,
        },
        {
            title: '身份证号码',
            dataIndex: 'idCard',
            width: 180,
        },
        {
            title: '银行卡账号',
            dataIndex: 'pbankcard',
            width: 180,
        },
        {
            title: '开户行',
            dataIndex: 'pbank',
            width: 120,
        },
        {
            title: '操作',
            width: 100,
            fixed: "right" as "right",
            render: (data: any) => <>
                <Button type={'link'} size={'small'} onClick={() => edit(data)}>编辑</Button>
            </>
        },
    ];
    const columns_C = [
        {
            title: '姓名',
            dataIndex: 'pname',
            width: 220,
        },
        {
            title: '手机号码',
            dataIndex: 'pphone',
            width: 220,
        },
        {
            title: '身份证号码',
            dataIndex: 'idCard',
            width: 220,
        },
        {
            title: '银行卡账号',
            dataIndex: 'pbankcard',
            width: 220,
        },
        {
            title: '开户行',
            dataIndex: 'pbank',
            width: 220,
        }
    ];

    const layout = {
        labelCol: { span: 8},
        wrapperCol: { span: 18 },
        onFinish: formPush,
        form: formSet
    };
    const tableOptions = {
        rowKey: 'id',
        scroll:{x: 'max-content', y: 540},
        loading: resData.loading,
        columns: APINAME === 'customer' ?columns_C: columns_B,
        dataSource: resData.page,
        onChange: (data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }
    const tip1 = '注：模板中“签约客户名称”和“身份证号码”为必填项，可根据身份证号码修改姓名、手机号码、银行卡账号、开户行'
    return (
        <div className="content-page">

            <div className='c_flex c_flex_up c_flex_rl' style={{marginBottom: 20}}>
                <div>
                    <Search placeholder={`输入${APINAME=== 'company'? '客户名称、': ''}姓名、手机号、身份证号、银行卡号回车搜索`}
                            style={{width: 450}} onSearch={value => setAjaxData({...ajaxData, ...{keyword: value, page: 1}})} enterButton />
                </div>
                <div>
                    <Button className='c_mr_10' type={'primary'} onClick={() => setputOnoff( 1)}>导入</Button>
                    <Button className='c_mr_10' type={'primary'} onClick={add}>单人添加</Button>
                    <Button type={'primary'} onClick={() => setputOnoff( 2)}>批量修改</Button>
                </div>
            </div>
            <Table {...tableOptions} />

            {  // 导入人员
                putOnoff === 1 &&
                <ImportsBox onClose={(flag: Boolean) => {setputOnoff( 0);init()}} title={'导入人员'} url2={`/api/Non-eeds/check/${APINAME}/importPerson`} />
            }
            {  // 批量修改
                putOnoff === 2 &&
                <ImportsBox onClose={(flag: Boolean) => {setputOnoff( 0);init()}} title={'批量修改'} tip1={tip1} url2={`/api/Non-eeds/check/${APINAME}/updatePerson`} />
            }

            <Drawer
                width={540}
                title={opeData.title}
                placement="right"
                closable={false}
                onClose={() => {setopeData({...opeData, ...{flag: false, title: ''}})}}
                visible={opeData.flag}
            >
                <Form {...layout}>
                    {
                        APINAME === 'company' &&
                        <Form.Item
                            label="签约客户"
                            name="customerName"
                            rules={[{ required: true}]}
                        >
                            <Select>
                                {
                                    customerList.page.map((item: any) => {
                                        return  <Option value={item.customerName} key={item.customerName} >{item.customerName}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    }


                    {
                        columns_C.map((item) => <Form.Item
                                label={item.title}
                                name={item.dataIndex}
                                rules={[{ required: true}]}
                            >
                                <Input placeholder='请输入'  />
                            </Form.Item>
                        )

                    }

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>

        </div>
    );
}

const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig,
        pageSizeOptions: state.golbal.pageSizeOptions,
        publicInfo: state.golbal.publicInfo
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        api_all: (name:string, data: API.InterfaceData, ) => {
            dispatch(api_index({...data})(name))
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(Member)
