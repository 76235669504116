import React, { useEffect, useState} from "react";
import {
    Button,
    Form, Input,
    Modal,
    Select,
    Table,
    Radio, DatePicker
} from 'antd';
import TaxCom from '@/components/taxSelect'
import {

} from '@ant-design/icons';
import {CaseTax} from './select_case_tax'
import {ajax} from "@/store/midPromise";
import moment from 'moment';
import {Dispatch} from "redux";
import {connect} from "react-redux";
import PayTye from './payType'

const { RangePicker } = DatePicker;
const {Option} = Select

const layout = {
    labelCol: { span: 6},
    wrapperCol: { span: 16 },
    scrollToFirstError: true
};
const layoutOpe1 = {
    wrapperCol: { span: 16, offset: 6 },
}

const offsetTax = 6

const dateFormat = 'YYYY-MM-DD'

const mockFlag = true

interface InterfaceDDD {
    id: number,
    schemeName: string
}

const PlanComponent = (props: any) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')

    const [resData, setresData] = useState({total: 0, page: []})
    const [ajaxData, setAjaxData] = useState({
        schemeStatus: '',
        schemeName: '',
        page: 1,
        rows: 100
    })

    const [flag, setflag] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedCase, setSelectedCase] = useState<Array<number>>([])

    const [mockChange, setMockChange] = useState(false)

    const [formSet] = Form.useForm();
    let nowValues = {}

    const finish = (data: any) => {
        // console.log(data)
        const newData = {
            ...data,
            ...{
                planTerm:[
                    moment(data.planTerm&&data.planTerm[0]).format('YYYY-MM-DD'),
                    moment(data.planTerm&&data.planTerm[1]).format('YYYY-MM-DD'),
                ]
            }
        }
        // console.log(newData)
        props.onFinish(newData, props.form_id)
    }
    const onSelectChange = (selectedRowKeys: any, selectedRows:any) => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        // console.log(selectedRows)
        setSelectedRowKeys( selectedRowKeys );
    };
    const importsOk = () => {
        formSet.setFieldsValue({
            usePlan: selectedRowKeys[0],
            invoiceType: '',
            account: 10,
            serverPay: 20,
            cupPay: 20,
            cupsPay: 20,
            pupPay: 20,
            pupsPay: 20,
            perPay: 20
        })
        onChange({...nowValues, ...{usePlan: selectedRowKeys[0]}})
        setflag(false)
    }

    const columns = [
        {
            title: '方案名称',
            dataIndex: 'schemeName',
            width: 220,
        },
        {
            title: '关联产品',
            dataIndex: 'productName',
            width: 120,
        },
        {
            title: '税源地',
            dataIndex: 'taxSourceName',
            width: 160,
        },
        {
            title: '发票种类&税率',
            width: 200,
            render: (data: any) => <>
                {data.invoiceSpecial && <div>增值税专用发票、{data.invoiceSpecial}%</div>}
                {data.invoiceGeneral && <div>增值税普通发票、{data.invoiceGeneral}%</div>}
            </>
        },
        {
            title: '个人收入额预警',
            dataIndex: 'incomWarnning',
            width: 140,
            render: (data: any) => <div>{Number(data)===10? '是': '否'}</div>
        },
        {
            title: '预警详情',
            dataIndex: 'warns',
            width: 140,
            render: (data:any) => {
                return <>
                    {data && data.second>=0 && <div>单笔、{data.second}万元</div>}
                    {data && data.day>=0 && <div>每日、{data.day}万元</div>}
                    {data && data.week>=0 && <div>单周、{data.week}万元</div>}
                    {data && data.month>=0 && <div>单月、{data.month}万元</div>}
                    {data && data.season>=0 && <div>单季、{data.season}万元</div>}
                    {data && data.year>=0 && <div>单年、{data.year}万元</div>}
                </>
            }
        },
        {
            title: '交付主体',
            dataIndex: 'supplierName',
            width: 100,
        }
    ];
    const rowSelection = {
        columnTitle: '',
        hideSelectAll: true,
        type: "radio" as "radio",
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record: any) => ({
            disabled: selectedCase.indexOf(record.id) >= 0
        }),
    };
    const tableOptions={
        rowKey: 'id',
        scroll: { y: 340 }
    }

    const valueChange = (changeValues: any, allValues: any) => {
        // console.log(changeValues, allValues)
        // console.log(changeValues)
        nowValues = {...allValues}
        onChange(nowValues)
    }

    const onChange = (allValues: any) => {
        if (props.getChange) props.getChange(allValues, props.form_id)
    }

    const planChange = (data:any) => {
        setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}})
    }

    const changeItem = (data: any) => {
        nowValues = {...nowValues, ...data}
        console.log(data)
        // console.log(nowValues)
        onChange(nowValues)
        // setMockChange(true)
        // formSet.getFieldsValue([''])
    }

    const init = () => {
        ajax.get(`/api/Non-eeds/reception/${APINAME}/scheme`, {params: ajaxData}).then((res: any) => {
            if(res && res.code === 200) setresData(res.data)
        })
    }

    const changeDataGet = () => {
        // setSelectedCase
        if (!props.detailInfo.planList) return
        let data = {...props.detailInfo.planList[props.form_id]}
        // 设置默认值
        data = {...data, ...{
                serverPay: 20,
                cupPay: 20,
                cupsPay: 20,
                pupPay: 10,
                pupsPay: 10,
            }}
        // 设置默认值结束
        // 转化时间格式
        const planTerm = (data.planTerm && [moment(data.planTerm[0]), moment(data.planTerm[1])]) || []
        data = {...data, ...{planTerm: planTerm}}
        formSet.setFieldsValue(data)
        // 转化时间格式结束
        // 获取已选择方案
        const planList = [...props.detailInfo.planList] || []
        const caseArr = planList.map((item) => item && item.usePlan);
        // console.log(caseArr)
        // @ts-ignore
        setSelectedCase([...caseArr])
        // 获取已选择方案结束

    }

    useEffect(() => {
        init()
    }, [ajaxData])

    useEffect(() => {
        changeDataGet()
    }, [props.detailInfo.planList])

    return(
        <>
            <Form {...layout} form={formSet} onFinish={finish} onValuesChange={valueChange}>
                <Form.Item label='方案有效期' name='planTerm' rules={[{ required: mockFlag}]}>
                    <RangePicker format={dateFormat} />
                </Form.Item>
                <Form.Item label='采用的方案' name='usePlan' rules={[{ required: mockFlag}]}>
                    <Select style={{width: 300}} onClick={() => setflag(true)}>
                        {
                            resData.page.map((item: InterfaceDDD) => {
                                return  <Option value={item.id} key={item.id} >{item.schemeName}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                {
                    formSet.getFieldValue('usePlan') &&
                    <Form.Item {...layoutOpe1}  label='' name='invoiceType' rules={[{ required: mockFlag}]}>
                        <CaseTax case_id={formSet.getFieldValue('usePlan')} getForm={formSet} onChange={() => {}}></CaseTax>
                    </Form.Item>
                }

                <Form.Item label='账单计算方式' name='account' rules={[{ required: mockFlag}]}>
                    <Radio.Group disabled={formSet.getFieldValue('usePlan')? true: false}>
                        <Radio value={10}>已知实发计算应发</Radio>
                        <Radio value={20}>已知应发计算实发</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label='服务费支付方式' name='serverPay' rules={[{ required: mockFlag}]}>
                    <PayTye case_id={formSet.getFieldValue('usePlan')} value={formSet.getFieldValue('serverPay')} />
                </Form.Item>
                <Form.Item label='服务费率' name='serverFeeRate' rules={[{ required: mockFlag}]}>
                    <Input placeholder='请输入' suffix={'%'} style={{width: 200}} />
                </Form.Item>
                <Form.Item label='服务费优惠金额' name='disServer' rules={[{ required: mockFlag}, {type: 'number', max: 999999,transform: (value: any) => Number(value)}]}>
                    <Input placeholder='请输入' suffix={'元'} style={{width: 200}}   />
                </Form.Item>
                <Form.Item label='企业增值税支付方式' name='cupPay' rules={[{ required: mockFlag}]}>
                    <PayTye case_id={formSet.getFieldValue('usePlan')} value={formSet.getFieldValue('serverPay')} />
                </Form.Item>

                <TaxCom label='企业增值税率' name='cup' getForm={formSet} onChange={changeItem} offset={offsetTax} />

                <Form.Item label='企业增值税附加税支付方式' name='cupsPay' rules={[{ required: mockFlag}]}>
                    <PayTye case_id={formSet.getFieldValue('usePlan')} value={formSet.getFieldValue('serverPay')} />
                </Form.Item>
                <TaxCom label='企业城市维护建设税税率' name='cupsCity' getForm={formSet} offset={offsetTax} onChange={changeItem} />
                <TaxCom label='企业教育费附加税率' name='cupsEdu' getForm={formSet} offset={offsetTax} onChange={changeItem} />
                <TaxCom label='企业税地方教育费附加税率' name='cupsLedu' getForm={formSet} offset={offsetTax} onChange={changeItem} />

                <Form.Item label='个人经营所得增值税支付方式' name='pupPay' rules={[{ required: mockFlag}]}>
                    <PayTye case_id={formSet.getFieldValue('usePlan')} value={formSet.getFieldValue('serverPay')} />
                </Form.Item>
                <TaxCom label='个人经营所得增值税税率' name='pup' getForm={formSet} offset={offsetTax} onChange={changeItem} />

                <Form.Item label='个人经营所得增值税附加税支付方式' name='pupsPay' rules={[{ required: mockFlag}]}>
                    <PayTye case_id={formSet.getFieldValue('usePlan')} value={formSet.getFieldValue('serverPay')} />
                </Form.Item>
                <TaxCom label='个人城市维护建设税税率' name='pupsCity' getForm={formSet} offset={offsetTax} onChange={changeItem} />
                <TaxCom label='个人教育费附加税率' name='pupsEdu' getForm={formSet} offset={offsetTax} onChange={changeItem} />
                <TaxCom label='个人地方教育费附加税率' name='pupsLedu' getForm={formSet} offset={offsetTax} onChange={changeItem} />

                <Form.Item label='个税支付方式' name='perPay' rules={[{ required: mockFlag}]}>
                    <PayTye case_id={formSet.getFieldValue('usePlan')} value={formSet.getFieldValue('serverPay')} />
                </Form.Item>
                <TaxCom label='个税税率' name='per' getForm={formSet} offset={offsetTax} onChange={changeItem} />

            </Form>

            {props.btn && <div className='c_text_right'><Button type="primary" onClick={() => formSet.submit()}>下一步</Button></div>}

            <Modal
                title="选择方案"
                width={900}
                visible={flag}
                onOk={importsOk}
                onCancel={() => setflag(false)}
            >
                <Table {...tableOptions} rowSelection={rowSelection}
                       columns={columns} dataSource={resData.page}
                       onChange={planChange}
                       pagination={{total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}}/>
            </Modal>
        </>
    )
}


const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig,
        pageSizeOptions: state.golbal.pageSizeOptions,
        detailInfo: state.index.customer_detail
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(PlanComponent)
