// 导入组件 后期根据需求修改

import React, {useEffect, useState} from "react";

import {
    Button,
    Modal,
    Upload
} from 'antd';

import {
    UploadOutlined,
    DownloadOutlined,
    ExclamationCircleTwoTone,
    CheckCircleTwoTone
} from '@ant-design/icons';

import {
    put_file,
} from '@/store/model/reducers/golbal'

import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {ajax} from "@/store/midPromise";

const { Dragger } = Upload;

interface Interfaceprops {
    onClose: (flag: Boolean) => void,
    putFiles: (data: any) => void,
    title: string,
    tip1?: any,
    url2: string
}
interface InterfaceImportInfo {
    successCount: number,
    failCount: number,
    failURL: string
}

const Box:React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')

    const [flag, setflag] = useState(true)
    const [fileList, setfileList] = useState<Array<any>>([])
    const [importInfo, setimportInfo] = useState<InterfaceImportInfo>({
        successCount: 0,
        failCount: 0,
        failURL: ''
    })
    const [exportFileLoading, setExportFileLoading] = useState(false)

    const importsOk = () => {
        props.onClose(false)
    }
    const importsCancel = () => {
        setflag(false)
        setTimeout(() => {
            props.onClose(flag)
        }, 200)
    }

    // @ts-ignore
    const handleChange = (info) => {
        const { file, onProgress, onSuccess, onError } = info;
        // console.log(info)
        const formData = new FormData()
        formData.append('file', file)
        ajax.post(`/api/Non-eeds/aliyunOss/${APINAME}/perUpload`, formData, {
            onUploadProgress: ({total, loaded}) =>  onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) }, file)
        }).then((res: any) => {
            if(res && res.code === 200) {
                onSuccess(res, file);
                setfileList([file]);
            }
        }).catch(() => {
            onError('123')
        })
        // setTimeout(() => {
        //     // @ts-ignore
        //     upload(file)
        // }, 200)
    }

    const downloadError = () => {
        // windowObj.GLOBAL_LIBS.downloadFile(`/api/Non-eeds/aliyunOss/${APINAME}/downloadError`)
        // ajax.get(`/api/Non-eeds/aliyunOss/${APINAME}/downloadError`).then((res: any) => {})
        // ajax.get(`/api/Non-eeds/aliyunOss/${APINAME}/downloadError`, {responseType: "blob"},).then((res: any) => {
        //     const name = windowObj.GLOBAL_LIBS.getHeaderValue(res.headers['content-disposition'], 'filename')
        //     // console.log(name)
        //     windowObj.GLOBAL_LIBS.downloadFile(res.data, name)
        // })
        setExportFileLoading(true)
        windowObj.GLOBAL_LIBS.downloadFile('person', () => {
            setExportFileLoading(false)
        })
    }
    const importFile = () => {
        ajax.post(props.url2).then((res: any) => {
            if(res && res.code === 200) {
                setimportInfo(res.data)
            }
        })
    }

    const uploads = {
        name: 'file',
        fileList: fileList,
        disabled: fileList.length > 0,
        onRemove: () => {
            setfileList([]);
        }
    }

    useEffect(() => {

    }, [])

    return (
        <Modal
            title={props.title}
            visible={flag}
            onOk={importsOk}
            onCancel={importsCancel}
        >
            <div className={'importFiles'}>
                <div className='c_mb_20'>
                    <p>1.下载文件</p>
                    <a href={`/api/Non-eeds/aliyunOss/${APINAME}/perDownload`} download='' target='_blank'>点击下载标准模板</a>
                    {  props.tip1&&
                        <p className="c_col_text_secondary px12 c_mt_5">{props.tip1}</p>
                    }
                </div>
                <div className='c_mb_20'>
                    <p>2.上传文件</p>
                    <Dragger {...uploads} customRequest={handleChange} >
                        <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                        </p>
                        <p className="ant-upload-text">点击或拖拽进行上传</p>
                        {/*<p className="ant-upload-hint">支持JPG、PNG、PDF文件</p>*/}
                    </Dragger>
                </div>
                <div>
                    <p>3.导入文件</p>
                    <Button type='primary' disabled={fileList.length>0? false: true} onClick={importFile}>导入文件</Button>
                </div>

                {
                    importInfo.successCount >0 && fileList.length > 0 &&
                    <div className='c_mt_10'>
                        <CheckCircleTwoTone twoToneColor="#52c41a" /> <span className='c_mr_5'></span>成功导入了{importInfo.successCount}个人员信息
                    </div>
                }
                {
                    importInfo.failCount >0 && fileList.length > 0 &&
                        <>
                            <div className='c_mt_10'>
                                <ExclamationCircleTwoTone twoToneColor="#eb2f96" /> <span className='c_mr_5'></span> {importInfo.failCount}个人员未完成导入，请下载这部分信息进行修改并重新导入。
                            </div>
                           <Button className='c_mt_10' type='link' icon={<DownloadOutlined />} size={'small'}
                                   onClick={downloadError} disabled={exportFileLoading}>点击下载错误信息{exportFileLoading&& '正在下载请稍后...'}</Button>
                        </>
                }

            </div>
        </Modal>
    )
}


const mapStateProps = (state: any) => {
    return {
        member_list: state.index.member_list
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        putFiles: (data: any) => {
            // 这个是获取所有人员数据
            dispatch(put_file(data))
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(Box);
