import * as Api from '../api'
import ConfigArr from '../../../statics/js/config/config-data'
/*
* 初始化state
 */
const initState = {
  arrConfig: ConfigArr,
  pageSizeOptions: ['10', '20', '50'],
  companyEmail: 'hrwuu.com',
  menuInfo: {openKey: []},
    publicInfo: {}, // 公共信息
    tracking_list: [], // 快递列表
};
/*
*
* actions
 */
export const changeMenuInfo = function (data: any) {
  // 更改导航栏信息
  return {
    type:  'CHANGE_MENU_INFO',
    data
  }
}

export const public_info = function () {
    // 获取公共信息
    return {
        type: "GETPUBLICINFO",
        promise: (client: any) => client.get(Api.UserInfo)
    }
}
export const customer_info = function () {
    // 获取客户信息
    return {
        type: "get_customer_info",
        promise: (client: any) => client.get(Api.customerInfo)
    }
}

export const get_tracking_list = function () {
    return {
        type: "get_tracking_list",
        promise: (client: any) => client.get(Api.tracking_list)
    }
}

export const getTime = (data: any) => {
  // 获取服务器时间
  return {
    type:  "GET_TIME",
    promise: (client: any) => client.post(Api.getTime,data)
  }
}

export const buryingErrMsg = (data:any) => {
  // 埋错误日志
  return {
    type:  "GOLBAL_error_msg",
    promise: (client: any) => client.post(Api.buryingErrMsg, data)
  }
}

export const put_file = (data:any) => {
    return {
        type:  "GOLBAL_put_file",
        promise: (client: any) => client.post(Api.putFiles, data)
    }
}


/*
* reducer
 */
export default function reducer(state = initState, action: any) {
    // console.log(action)
    switch (action.type) {
        case 'CHANGE_MENU_INFO':
          return {
            ...state,
            menuInfo: action.data
          };
        case "GETPUBLICINFO":
            localStorage.setItem('mainAppBase', JSON.stringify(action.result.data))
            return {
            ...state,
            publicInfo: action.result.data
          };
        case "get_customer_info":
            return {
                ...state,
                customer_info: action.result.data
            };

        case "get_tracking_list":
            return {
                ...state,
                tracking_list: action.result.data
            }
      default:
            return state
    }
}
