import React, {useEffect, useState} from "react";
import {
    Button, Checkbox, Drawer,
    Form, Input,
    Modal,
    Select,
    Table,
    Radio, DatePicker, Descriptions, message
} from 'antd';

import {TreeDepartment} from "@/components/select";
import {Dispatch} from "redux";
import {api_index, mock_table} from "@/store/model/reducers/index";
import {connect} from "react-redux";
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';

import {RouteComponentProps} from "react-router-dom";
import {ajax} from '@/store/midPromise'

const { confirm } = Modal;

interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    typeArr: any,
    get_mock: () => void,
    get_mock2: (data: API.InterfaceData) => void,
    publicInfo: any,
}


const TaxCheck:React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')

    const [flag, setFlag] = useState({detail: false})

    const [resData, setresData] = useState({total: '', page: [], loading: true})
    const [ajaxData, setAjaxData] = useState({page: 1, rows: 10})

    const [activeData, setActiveData] = useState({name: '', user_id: '', batch_id: ''})
    const [detailAjax, setDetailAjax] = useState({ page: 1, rows: 10})
    const [detailData, setDetailData] = useState({total: '', page: [] , loading: true})
    const [title, setTitle] = useState([])
    const goDetail = (data:any) => {
        console.log(data)
        const id = props.match.params && (props.match.params as any)?.id
        const user_id = data.personId
        setFlag({...flag, ...{detail: true}})
        setActiveData({name: data.personName, user_id, batch_id: id})
        getDetail(id, user_id)
    }
    const getDetail = (id?: string| number, user_id?: string| number) => {
        console.log(activeData)
        setDetailData({...detailData, ...{loading: true}})
        ajax.get(`/api/Non-eeds/check/taxBureau/getSample/${id|| activeData.batch_id}/${user_id || activeData.user_id}`,
            {params: detailAjax}).then((res: any) => {
            if(res && res.code === 200) {
                const list = res.data.tableHead.map((item: any) => {
                    return {...item, ...{width: 150}}
                })
                setTitle(list)
                setDetailData({...res.data.data, ...{loading: false}})
            }
        })
    }
    const closeDetail = () => setFlag({...flag, ...{detail: false}})
    const init = () => {
        const id =  props.match.params && (props.match.params as any)?.id
        setresData({...resData, ...{loading: true}})
        ajax.get(`/api/Non-eeds/check/taxBureau/getSamplePerson/${id}`, {params: ajaxData}).then((res: any) => {
            if(res && res.code === 200) setresData({...res.data, ...{loading: false}})
        })
    }
    useEffect (() => {
        init()
    }, [ajaxData])
    useEffect(() => {
        if(activeData.batch_id) getDetail()
    }, [detailAjax])
    const columns = [
        {
            title: '序号',
            width: 50,
            render: (text: any, record: any, index: number) => index + 1
        },
        {
            title: '姓名',
            dataIndex: 'personName',
            width: 100,
        },
        {
            title: '身份证号码',
            dataIndex: 'eid',
            width: 120,
        },
        {
            title: '手机号码',
            dataIndex: 'phone',
            width: 120,
        },
        {
            title: '金额',
            dataIndex: 'trueMoney',
            width: 120,
        },
        {
            title: '抽样状态',
            width: 120,
            render: (data: any) => <>
                {
                    data.sampleStatus == 10 ? '--':
                        <Button type={'link'} size={'small'} onClick={() => goDetail(data)}>
                            已抽样
                        </Button>
                }
            </>
        },
    ];
    const tableOptions = {
        rowKey: 'id',
        scroll:{x: 'max-content', y: 540},
        loading: resData.loading,
        columns: columns,
        dataSource: resData.page,
        onChange: (data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }
    const detailTableOptions = {
        rowKey: 'id',
        scroll:{x: 'max-content', y: 340},
        loading: detailData.loading,
        columns: title,
        dataSource: detailData.page,
        onChange: (data:any) => setDetailAjax({...detailAjax, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(detailData.total), pageSize: detailAjax.rows, current: detailAjax.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }
    return (
        <div className="content-page">
            <Table {...tableOptions} />

            <Modal title={activeData.name} visible={flag.detail} width={800}
                   onOk={closeDetail} onCancel={closeDetail}>
                <Table {...detailTableOptions} />

            </Modal>
        </div>
    );
}

const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig,
        pageSizeOptions: state.golbal.pageSizeOptions,
        publicInfo: state.golbal.publicInfo,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(TaxCheck)
