import React, {useEffect, useState} from "react";
import {
    Button, Checkbox, Drawer,
    Form, Input,
    Modal,
    Select,
    Table,
    Radio, message
} from 'antd';

// import {TreeDepartment} from "@/components/select";
import {Dispatch} from "redux";
import {api_index, mock_table} from "@/store/model/reducers/index";
import {connect} from "react-redux";
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';
// import InputComponent from '@/components/inputBox/index'

import {RouteComponentProps} from "react-router";
import {ajax} from "@/store/midPromise";

const { confirm } = Modal;

interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    typeArr: [{name: string, value: string}],
    get_mock: () => void,
    api_all: (name:string, data: API.InterfaceData) => void,
}


const { Search } = Input;
const { Option } = Select;

const options = [
    { label: '增值税专用发票', value: 'invoiceSpecial' },
    { label: '增值税普通发票', value: 'invoiceGeneral' }
];
const ajaxData1 = {
    supplierName: '',
    productType: 2,
    productStatus: 10,
    supplierStatus: 10,
    taxSourceStatus: 10,
    page: 1,
    rows: 100
}

const DeliveryPlan:React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;

    const [resData, setresData] = useState({total: '', page: [], loading: true})
    const [opeData, setopeData] = useState({flag: false, title: '', id: ''})
    const [ajaxData, setAjaxData] = useState({
        schemeStatus: '',
        schemeName: '',
        page: 1,
        rows: 10
    })

    const [taxList, settaxList] = useState({total: '', page: []})
    const [supplierList, setsupplierList] = useState({total: '', page: []})
    const [productList, setproductList] = useState({total: '', page: []})
    const [incomWarnning, setincomWarnning] = useState(0)

    const [invoiceCheck, setinvoiceCheck] = useState([''])

    const [formSet] = Form.useForm();
    // const getText = (data: any) => {
    //     return {
    //         text: data.supplierStatus==='10'? "停用": "启用",
    //         status: data.supplierStatus==='10'? "20": "10"
    //     }
    // }

    const supplierChange = (data:any) => {
        console.log(data)
        ajaxData1.supplierName = data
        getSup()
    }
    const getSup = () => {
        ajax.get(`/api/Non-eeds/admin/business/supplier`, {params: ajaxData1}).then((res: any) => {
            if(res && res.code === 200) setsupplierList(res.data)
        })
    }

    const getSelect = () => {
        ajax.get(`/api/Non-eeds/admin/business/tax`, {params: ajaxData1}).then((res: any) => {
            if(res && res.code === 200) settaxList(res.data)
        })
        getSup()
        ajax.get(`/api/Non-eeds/admin/business/product`, {params: ajaxData1}).then((res: any) => {
            if(res && res.code === 200) setproductList(res.data)
        })
    }
    const resetForm = () => {
        formSet.resetFields()
    }
    const add = () => {
        resetForm()
        formSet.setFieldsValue( {
            incomWarnning: 10,
            invoiceCheck: []
        });
        getSelect()
        setopeData({...opeData, ...{flag: true, title: '新增'}})
    }
    const getSelInit = () => {
        setinvoiceCheck(formSet.getFieldValue('invoiceCheck'))
        setincomWarnning(formSet.getFieldValue('incomWarnning'))
    }
    const edit = (data: any) => {
        getSelect()
        resetForm()
        setopeData({flag: true, title: '编辑', id: data.id})
        const invoiceCheck = []
        data.invoiceSpecial && invoiceCheck.push('invoiceSpecial')
        data.invoiceGeneral && invoiceCheck.push('invoiceGeneral')
        formSet.setFieldsValue( {...data, ...{
            invoiceCheck: invoiceCheck,
                // supplierId: data.supplierName
            }});
        getSelInit()
        // console.log(formSet, formSet.getFieldValue('invoiceCheck'))
    }

    const formPush = (data: any) => {
        console.log(data)
        if(opeData.title === '新增') {
            ajax.post(`/api/Non-eeds/admin/business/scheme`, data).then((res: any) => {
                if(res && res.code === 200) {
                    setopeData({...opeData, ...{flag: false, title: ''}})
                    message.success('成功')
                    ajaxData1.supplierName = ''
                    init()
                }
            })
        }
        if (opeData.title === '编辑') {
            ajax.put(`/api/Non-eeds/admin/business/scheme/${opeData.id}`, data).then((res: any) => {
                if(res && res.code === 200) {
                    setopeData({...opeData, ...{flag: false, title: ''}})
                    message.success('成功')
                    init()
                }
            })
        }
    }
    const changeStatus = (data: any) => {
        // console.log(data)
        const text = windowObj.GLOBAL_LIBS.getStatus(data.schemeStatus, true, true)
        const status = windowObj.GLOBAL_LIBS.getStatus(data.schemeStatus, false, true)

        confirm({
            title: `确认要对该方案进行【${text}】吗？`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk() {
                ajax.put(`/api/Non-eeds/admin/business/scheme/${data.id}/status`, {status}).then((res: any) => {
                    if(res && res.code === 200) {
                        message.success('成功')
                        init()
                    }
                })
                // props.api_all('put_pass_deliveryPlan', {api: `/api/scheme/${data.id}`, success: () => init() })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const init = () => {
        ajax.get(`/api/Non-eeds/admin/business/scheme`, {params: ajaxData}).then((res: any) => {
            setresData({...resData, ...{loading: false}})
            if(res && res.code === 200) setresData({...res.data})
        })
    }
    useEffect (() => {
        init()
    }, [ajaxData])

    const columns = [
        {
            title: '方案名称',
            dataIndex: 'schemeName',
            width: 220,
        },
        {
            title: '关联产品',
            dataIndex: 'productName',
            width: 120,
        },
        {
            title: '税源地',
            dataIndex: 'taxSourceName',
            width: 160,
        },
        {
            title: '发票种类&税率',
            width: 200,
            render: (data: any) => <>
                {data.invoiceSpecial && <div>增值税专用发票、{data.invoiceSpecial}%</div>}
                {data.invoiceGeneral && <div>增值税普通发票、{data.invoiceGeneral}%</div>}
            </>
        },

        {
            title: '个人收入额预警',
            dataIndex: 'incomWarnning',
            width: 140,
            render: (data: any) => <div>{Number(data)===10? '是': '否'}</div>
        },
        {
            title: '预警详情',
            dataIndex: 'warns',
            width: 140,
            render: (data:any) => {
                return <>
                    {data && data.second && <div>单笔、{data.second}万元</div>}
                    {data &&data.day && <div>每日、{data.day}万元</div>}
                    {data &&data.week && <div>单周、{data.week}万元</div>}
                    {data &&data.month && <div>单月、{data.month}万元</div>}
                    {data &&data.season && <div>单季、{data.season}万元</div>}
                    {data &&data.year && <div>单年、{data.year}万元</div>}
                </>
            }
        },
        {
            title: '交付主体',
            dataIndex: 'supplierName',
            width: 100,
        },
        {
            title: '状态',
            dataIndex: 'schemeStatus',
            width: 100,
            render: (data: any) => <div>{data===10? '已启用': data===20? '已停用': '--'}</div>,
        },
        {
            title: '操作',
            width: 180,
            fixed: 'right' as 'right',
            render: (data: any) => <>
                <Button type={'link'} size={'small'} onClick={() => edit(data)}>编辑</Button>
                <Button type={'link'} size={'small'} onClick={() => changeStatus(data)}>{windowObj.GLOBAL_LIBS.getStatus(data.schemeStatus, true, true)}</Button>
            </>
        },
    ];
    const layout = {
        labelCol: { span: 8},
        wrapperCol: { span: 18 },
        onFinish: formPush,
        form: formSet,
        onValuesChange: () => getSelInit()

    };
    const layoutOpe = {
        wrapperCol: { span: 18, offset: 8 },
        style: {
            margin: '0 0 0 0px'
        }
    }
    const layoutOpe1 = {
        style: {
            display: 'inline-block',
            marginLeft: '10px'
        }
    }
    const tableOptions = {
        rowKey: 'id',
        scroll:{x: 'max-content', y: 540 },
        loading: resData.loading,
        columns: columns,
        dataSource: resData.page,
        onChange: (data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }

    return (
        <div className="content-page">
            <div className='c_flex c_flex_up c_flex_rl' style={{marginBottom: 20}}>
                <div>
                    <Search placeholder="输入方案名称搜索" style={{width: 320}}
                            onSearch={value => setAjaxData({...ajaxData, ...{schemeName: value, page: 1}})} enterButton />
                    <span className='c_ml_20'>状态:</span>
                    <Select defaultValue="0" style={{ width: 120, marginLeft: 20 }}
                            onChange={(value: string) => setAjaxData({...ajaxData, ...{schemeStatus: value !=='0'? value: '', page: 1}})}>
                        <Option value={'0'} >全部</Option>
                        {
                            props.typeArr.map((item) => {
                                return  <Option value={item.value} key={item.value} >{item.name}</Option>
                            })
                        }
                    </Select>
                </div>
                <Button type={'primary'} onClick={add}>新增</Button>
            </div>

            <Table
                {...tableOptions}
                columns={columns}
                dataSource={resData.page}
                onChange={(data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}})}
                pagination={{total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}}
            />

            <Drawer
                width={640}
                title={opeData.title}
                placement="right"
                closable={false}
                onClose={() => {setopeData({...opeData, ...{flag: false, title: ''}})}}
                visible={opeData.flag}

            >
                <Form {...layout}>
                    <Form.Item label="方案名称" name="schemeName" rules={[{ required: true, message: '请填写方案名称'}]}>
                        <Input placeholder='请填写'  />
                    </Form.Item>
                    <Form.Item label="关联产品" name="productId" rules={[{ required: true, message: '请选择关联产品'}]}>
                        <Select >
                            {
                                productList.page.map((item:any) => {
                                    return  <Option value={item.id} key={item.id} >{item.productName}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="选择税源地" name="taxId" rules={[{ required: true}]}>
                        <Select>
                            {
                                taxList.page.map((item:any) => {
                                    return  <Option value={item.id} key={item.id} >{item.taxSourceName}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item label="发票种类" name="invoiceCheck" rules={[{ required: true}]}>
                        <Checkbox.Group options={options} />
                    </Form.Item>

                    {
                        invoiceCheck.includes("invoiceSpecial") &&
                        <Form.Item label="增值税专用发票税率" name="invoiceSpecial" rules={[{ required: true}]}>
                            <Input placeholder='请输入税率'  suffix="%" />
                        </Form.Item>
                    }
                    {
                        formSet.getFieldValue('invoiceCheck') && formSet.getFieldValue('invoiceCheck').includes("invoiceGeneral") &&
                        <Form.Item label="增值税普通发票税率" name="invoiceGeneral" rules={[{ required: true}]}>
                            <Input placeholder='请输入税率'  suffix="%" />
                        </Form.Item>
                    }

                    <Form.Item
                        label="对账单是否限额"
                        name="incomWarnning"
                        rules={[{ required: true}]}
                    >
                        <Radio.Group >
                            <Radio value={10}>是</Radio>
                            <Radio value={20}>否</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {
                        formSet.getFieldValue('incomWarnning') === 10 &&
                         <>
                             <Form.Item{...layoutOpe} label="">
                                 <Form.Item {...layoutOpe1} >
                                     单次
                                     {/*<Checkbox >单次</Checkbox>*/}
                                 </Form.Item>
                                 <Form.Item {...layoutOpe1} name={['warns','second']} >
                                     <Input placeholder='请输入限制金额' suffix="万元" />
                                 </Form.Item>
                             </Form.Item>
                             <Form.Item{...layoutOpe} label="">
                                 <Form.Item {...layoutOpe1}>
                                     每日
                                     {/*<Checkbox >每日</Checkbox>*/}
                                 </Form.Item>
                                 <Form.Item {...layoutOpe1} name={['warns','day']} >
                                     <Input placeholder='请输入限制金额' suffix="万元" />
                                 </Form.Item>
                             </Form.Item>

                             <Form.Item{...layoutOpe} label="">
                                 <Form.Item {...layoutOpe1}>
                                     每周
                                     {/*<Checkbox >每周</Checkbox>*/}
                                 </Form.Item>
                                 <Form.Item {...layoutOpe1} name={['warns','week']} >
                                     <Input placeholder='请输入限制金额' suffix="万元" />
                                 </Form.Item>
                             </Form.Item>
                             <Form.Item{...layoutOpe} label="">
                                 <Form.Item {...layoutOpe1}>
                                     每月
                                     {/*<Checkbox >每月</Checkbox>*/}
                                 </Form.Item>
                                 <Form.Item {...layoutOpe1} name={['warns','month']} >
                                     <Input placeholder='请输入限制金额' suffix="万元" />
                                 </Form.Item>
                             </Form.Item>
                             <Form.Item{...layoutOpe} label="">
                                 <Form.Item {...layoutOpe1}>
                                     每季
                                     {/*<Checkbox >每季</Checkbox>*/}
                                 </Form.Item>
                                 <Form.Item {...layoutOpe1} name={['warns','season']} >
                                     <Input placeholder='请输入限制金额' suffix="万元" />
                                 </Form.Item>
                             </Form.Item>
                             <Form.Item{...layoutOpe} label="">
                                 <Form.Item {...layoutOpe1}>
                                     每年
                                     {/*<Checkbox >每年</Checkbox>*/}
                                 </Form.Item>
                                 <Form.Item {...layoutOpe1} name={['warns','year']}>
                                     <Input placeholder='请输入限制金额' suffix="万元" />
                                 </Form.Item>
                             </Form.Item>
                         </>
                    }


                    <Form.Item
                        label="交付主体"
                        name="supplierId"
                        rules={[{ required: true, message: '请选择交付主体'}]}
                    >
                        <Select showSearch filterOption={false}
                                onSearch={supplierChange} onChange={supplierChange}>
                            {
                                supplierList.page.map((item:any) => {
                                    return  <Option value={item.id} key={item.id} >{item.supplierName}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
}

const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig.deliveryPlan_status,
        pageSizeOptions: state.golbal.pageSizeOptions,
        member_list: state.index.member_list,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get_mock: () => {
            dispatch(mock_table())
        },
        api_all: (name:string, data: API.InterfaceData, ) => {
            dispatch(api_index({...data})(name))
        }

    }
};

export default connect(mapStateProps, mapDispatchToProps)(DeliveryPlan);

