import React, {useEffect, useState} from "react";
import {
    Button, Checkbox, Drawer,
    Form, Input,
    Modal,
    Select,
    Table,
    Radio, DatePicker, Card, Tabs, Descriptions
} from 'antd';

import {Dispatch} from "redux";
import {connect} from "react-redux";
const {Option} = Select
const mockFlag = true


const layout = {
    labelCol: { span: 5},
    wrapperCol: { span: 19 },
    validateMessages: {
        pattern: {
            mismatch: "格式不正确，请修改",
        }
    }
};

const BaseComponent = (props:any) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')
    const baseList = [
        {
            name: 'customerName',
            lable: '签约客户名称',
            rules: [{min: 2, max: 50}]
        },
        {
            name: 'adminName',
            lable: '主管理员姓名',
            rules: [{min: 2, max: 20}]
        },
        {
            name: 'adminPhone',
            lable: '主管理员手机号',
            rules: [{type: 'number',transform: (value: any) => Number(value)}, {pattern: windowObj.GLOBAL_LIBS.regs.phone}]
        },
        {
            name: 'customerMail',
            lable: '邮箱账号',
            rules: [{type: 'email'}]
        },
        {
            name: 'creditCode',
            lable: '统一信用代码',
            rules: [{}]
        },
        {
            name: 'legalPerson',
            lable: '法人',
            rules: [{min: 2, max: 20}]
        },
        {
            name: 'customerAddress',
            lable: '公司地址',
            rules: [{min: 2, max: 100}]
        },
        {
            name: 'customerPhone',
            lable: '公司联系电话（固话/手机号码）',
            rules: [{}]
        },
        {
            name: 'receiveName',
            lable: '发票接收联系人',
            rules: [{}]
        },
        {
            name: 'receivePhone',
            lable: '联系人手机号',
            rules: [{type: 'number', transform: (value: any) => Number(value)}, {pattern: windowObj.GLOBAL_LIBS.regs.phone}]
        },
        {
            name: 'receiveAddress',
            lable: '发票快递地址',
            rules: [{max: 100}]
        }
    ]

    const [formbase] = Form.useForm();
    const [pageChange, setPageChange]= useState(0)

    const basePush = () => {
        formbase.submit()
    }
    const finishbase = (data:any) => {
        // console.log(data)
        props.onFinish(data)
    }
    const zhouqiChange = (data:any) => {
        setPageChange(data.target.value)
        // console.log(data.target.value)
    }
    const valueChange = (changeValues: any, allValues: any) => {
        // console.log(changeValues, allValues)
        allValues.customerName = allValues.customerName && allValues.customerName.replace(/^\s+|\s+$/g,"");
        if (props.getChange) props.getChange(allValues)
    }

    const init = () => {
        // console.log(props.detailInfo)
        formbase.setFieldsValue(props.detailInfo)
        // console.log(formbase.getFieldValue('billStyle') === 10)
    }

    useEffect(()=> {
        init()
    }, [props.detailInfo])

    return(
        <>
            <Form {...layout} form={formbase} onFinish={finishbase} onValuesChange={valueChange}>
                {
                    baseList.map((item) =>
                        <Form.Item label={item.lable} name={item.name} rules={[{ required: mockFlag}, ...item.rules]} key={item.name}>
                            <Input placeholder='请输入'  />
                        </Form.Item>)
                }
                <Form.Item label={'对账单周期'} name={'billStyle'} rules={[{ required: mockFlag}]}>
                    <Radio.Group onChange={zhouqiChange}>
                        <Radio value={10}>月度</Radio>
                        <Radio value={20}>周</Radio>
                    </Radio.Group>
                </Form.Item>
                {
                    formbase.getFieldValue('billStyle') === 10 &&
                    <Form.Item label={'账单起始日'} name={'billDate'} rules={[{ required: mockFlag}]}>
                        <Select >
                            {
                                props.typeArr.mouthList.map((item:any) => {
                                    return  <Option value={item.value} key={item.value} >{item.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                }
                {props.btn && <div className='c_text_right'><Button type="primary" onClick={basePush}>下一步</Button></div>}

            </Form>
        </>

    )
}


const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig,
        pageSizeOptions: state.golbal.pageSizeOptions,
        detailInfo: state.index.customer_detail
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(BaseComponent)
