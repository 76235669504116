
import React, {useEffect, useState} from "react";
import {
    Button, Checkbox, Drawer,
    Form, Input,
    Modal,
    Select,
    Table,
    Radio, DatePicker, Descriptions, message
} from 'antd';

import {TreeDepartment} from "@/components/select";
import {Dispatch} from "redux";
import {api_index, mock_table} from "@/store/model/reducers/index";
import {connect} from "react-redux";
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';

import {RouteComponentProps} from "react-router";
import {ajax} from '@/store/midPromise'

const { confirm } = Modal;

interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    typeArr: any,
    get_mock: () => void,
    get_mock2: (data: API.InterfaceData) => void,
    publicInfo: any
}


const TaxCheck:React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')

    const [resData, setresData] = useState({total: '', page: [], loading: true})
    const [ajaxData, setAjaxData] = useState({page: 1, rows: 10})

    const goDetail = (data:any) => {
        props.history.push(`/flexibleLabor/tax/check_detail/${data.batchId}`)
    }
    const init = () => {
        ajax.get(`/api/Non-eeds/check/${APINAME}/getSampleCustomer`, {params: ajaxData}).then((res: any) => {
            if(res && res.code === 200 && res.data) setresData(res.data)
        })
    }
    useEffect (() => {
        init()
    }, [ajaxData])
    const columns = [
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 160,
        },
        {
            title: '对账单周期',
            dataIndex: 'billTerm',
            width: 100,
        },
        {
            title: '对账单人数(人)',
            dataIndex: 'billPerson',
            width: 120,
        },
        {
            title: '操作',
            width: 100,
            fixed: "right" as "right",
            render: (data: any) => <>
                <Button type={'link'} size={'small'} onClick={() => goDetail(data)}>查看</Button>
            </>
        },
    ];
    const tableOptions = {
        rowKey: 'id',
        scroll:{x: 'max-content', y: 540},
        loading: resData.loading,
        columns: columns,
        dataSource: resData.page,
        onChange: (data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }
    return (
        <div className="content-page">
            <Table {...tableOptions} />
        </div>
    );
}

const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig,
        pageSizeOptions: state.golbal.pageSizeOptions,
        publicInfo: state.golbal.publicInfo,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(TaxCheck)
