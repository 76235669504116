import React, {useEffect, useState, useCallback} from "react";
import {
    Button, Drawer,
    Form, Input,
    Modal,
    Table,
    Radio, Descriptions, List, message, Spin, Alert,
    Dropdown,
    Menu
} from 'antd';

import {Dispatch} from "redux";
import {connect} from "react-redux";
import {
    DownOutlined
} from '@ant-design/icons';

import {RouteComponentProps} from "react-router";
import {DataTitle} from './data'
import {ajax} from "@/store/midPromise";


interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    typeArr: any,
    get_mock: () => void,
    get_mock2: (data: API.InterfaceData) => void,
}
interface InterfaceResData {
    billDetails: {
        total: number,
        page: []
    }
}
interface InterfaceHandel {
    id: number,
    handleTime: string,
    handleName: string,
    handleStatus: number,
    handleDetail: string
}

interface InterfaceResData {
    page: any[],
    total: number,
    auditStatus: 10 | 20 | 30 | 40,
    billDetails: { total: number; page: []; }
}

const { Search } = Input;
const { TextArea } = Input;


const desOptions={
    bordered: true,
    column:{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }
}

const ManageDetail:React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')
    const billId = windowObj.GLOBAL_LIBS.getQuery(props.location.search, 'id')

    const total_title = [
        {
            title: '合计',
            width: 120,
            key: 'add'
        },
        {
            title: '应发总额',
            dataIndex: 'sumShould',
            width: 160,
        },
        {
            title: '实发金额',
            dataIndex: 'sumReal',
            width: 160,
        },
        {
            title: '个人经营所得增值税',
            dataIndex: 'sumPerGet',
            width: 160,
        },
        {
            title: '个人经营所得增值税附加税',
            dataIndex: 'sumPerAdd',
            width: 160,
        },
        {
            title: '个人所得税',
            dataIndex: 'sumPer',
            width: 160,
        },
        {
            title: '服务费',
            dataIndex: 'sumServer',
            width: 160,
            // render: (data: any) => APINAME==='customer'? data.lastServerFee : data.sumServer
        },
        {
            title: '企业增值税',
            dataIndex: 'sumComGet',
            width: 160,
        },
        {
            title: '企业增值税附加税',
            dataIndex: 'sumComAdd',
            width: 160,
        },
        {
            title: '到款总额',
            dataIndex: 'sumCome',
            width: 160,
            // render: (data: any) => APINAME==='customer'? data.sumComec : data.sumCome
        }
    ]
    const tableOptions = {
        rowKey: 'id',
        scroll:{x: 'max-content', y: 540},

    }

    // const mapTaxList = useCallback( (list:any[]) => {
    //     // console.log(list)
    //     return list.map((item) => {
    //         return <div>{item.first + item.buttons + '收入额' + item.buttonb + item.last + ' ' + item.tax}</div>
    //     })
    // }, [])

    const [resData, setresData] = useState<InterfaceResData>({page: [], auditStatus: 10, total: 0, billDetails: { total: 0, page: [] } })
    const [statusForm, setstatusForm] = useState({flag: false, title: '', loading: false})
    const [opeData, setopeData] = useState({flag: false, title: ''})
    const [ajaxData, setAjaxData] = useState({page: 1, rows: 10, keyword: ''})
    const [selfTotalData, setselfTotalData] = useState<any>({
        sumShould: '',
        sumReal: '',
        sumPerGet: '',
        sumPerAdd: '',
        sumPer: '',
        sumServer: '',
        sumComGet: '',
        sumComAdd: '',
        sumCome: '',
        showServer: '0'
    })
    const [title, settitle] = useState('')
    const [handleList, setHandleList] = useState<Array<InterfaceHandel>>([])
    const [mockData, setMockdata] = useState(0)
    const [exportFileLoading, setExportFileLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dataLoading, setdataLoading] = useState(false)

    const getHandle = useCallback(() => {
        setopeData({flag: true, title: '操作记录'})
        ajax.get(`/api/Non-eeds/check/company/showHandle/${billId}`, {params: {rows: 200}}).then((res: any) => {
            if(res && res.code === 200) {
                console.log(res.data)
                setHandleList(res.data.page)
            }
        })
    }, [])
    const changeZhe = (data: any) => {
        // console.log(data)
        setselfTotalData({...selfTotalData, ...{serverFree: data.target.value}})
        // ajax.put(`/api/Non-eeds/check/company/serverFee/${billId}`, {serverFree: data.target.value}).then((res: any) => {
        //     if(res && res.code === 200) {
        //         // message.success('成功')
        //     }
        // })
    }

    const pushKaiPiao = (data: any) => {
        setstatusForm({...statusForm, ...{loading: true}})
        // confirmStatus
        if(statusForm.title === '审核') {
            const ajaxData = {...data, ...{
                    serverFree: selfTotalData.serverFree,
                    freeReason: selfTotalData.freeReason,
                    showServer: selfTotalData.showServer
                }}
            ajax.put(`/api/Non-eeds/check/${APINAME}/auditStatus/${billId}/auditStatus`, ajaxData).then((res: any) => {
                if(res && res.code === 200) {
                    message.success('成功')
                    init()
                }
                setstatusForm({...statusForm, ...{loading: false, flag: false}})
            })
        }
        if (statusForm.title === '确认对账单') {
            ajax.put(`/api/Non-eeds/check/${APINAME}/confirmStatus/${billId}/confirmStatus`, data).then((res: any) => {
                if(res && res.code === 200) {
                    message.success('成功')
                    init()
                }
                setstatusForm({...statusForm, ...{loading: false, flag: false}})
            })
        }

    }

    const recalculation = () => {
        // /company/count
        setLoading(true)
        ajax.put(`/api/Non-eeds/check/company/serverFee/${billId}`, {serverFree: selfTotalData.serverFree}).then((res: any) => {
            if(res && res.code === 200) {
                init()
            }
            setLoading(false)
        })
    }
    const exportFile = () => {
        setExportFileLoading (true)
        windowObj.GLOBAL_LIBS.downloadFile('bill', () => {
            setExportFileLoading(false)
        }, billId)
    }
    const onChangeReason = (e: any) => {
        setselfTotalData({...selfTotalData, ...{showServer: e.target.value}})
    }
    const onChangeReason1 = (e: any) => {
        console.log(e.target.value)
        setselfTotalData({...selfTotalData, ...{freeReason: e.target.value}})

    }
    const handleMenuClick = (e: any) => {
        console.log('click', e);
        setExportFileLoading (true)
        if(e.key==='1') {
            windowObj.GLOBAL_LIBS.downloadFile('zBill', () => {
                setExportFileLoading(false)
            }, billId)
        }
        if(e.key==='2') {
            windowObj.GLOBAL_LIBS.downloadFile('bill', () => {
                setExportFileLoading(false)
            }, billId)
        }

    }

    const [formSet] = Form.useForm();

    const init = () => {
        setdataLoading(true)
        ajax.get(`/api/Non-eeds/check/${APINAME}/${billId}`, {params: ajaxData}).then((res: any) => {
            setdataLoading(false)
            if(res && res.code === 200) {
                const resDataLL = res.data
                setresData(res.data.billDetails)
                setselfTotalData({...selfTotalData, ...res.data})
                const timer = JSON.parse(resDataLL.billCycle)
                const status = resDataLL.auditStatus === 20 ? '正在审核': resDataLL.auditStatus === 40 ? '驳回': resDataLL.auditStatus === 10? '复议，待审核' : resDataLL.confirmStatus === 20? '已确认': '未确认'
                const statusAdmin = resDataLL.auditStatus === 20 ? '未审核': resDataLL.auditStatus === 40 ? '驳回': resDataLL.auditStatus === 10? '复议' : resDataLL.confirmStatus === 20?'已确认': '未确认'
                const str = timer[0] + '~~~'+ timer[1] + '（' +(APINAME==='customer'? status: statusAdmin )+ '）'
                settitle( str )
            }
        })
    }

    useEffect (() => {
        init()
    }, [ajaxData])
    const plainOptions = [
        { label: '隐藏', value: '0' },
        { label: '显示', value: '1' },
    ];
    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1" >
                导出招行转账表
            </Menu.Item>
            <Menu.Item key="2" >
                导出计算详单
            </Menu.Item>
        </Menu>
    );
    const op = {
        autoSize: true,
        maxLength: 200,
        showCount: true,
        bordered: false
    }
    const textareaprop = {
        autoSize:true,
        maxLength:500,
        showCount:true
    }
    return (
        <div className="content-page">
            {
                !loading &&
                <>
                    <Descriptions title={title} bordered  column={3}>
                        <Descriptions.Item label={<div style={{width: 200}}>原始对账单</div>} span={3}>
                            <div style={{width: 600}}><a href={selfTotalData.path} type='primary'>{ selfTotalData.filename}</a></div>
                        </Descriptions.Item>
                        {
                            selfTotalData.backReason &&
                            <Descriptions.Item label={<div style={{width: 200}}>审核备注</div>} span={3}> <div className='c_error'  style={{width: 600}}>{selfTotalData.backReason}</div> </Descriptions.Item>
                        }
                        {
                            selfTotalData.confirmReason &&
                            <Descriptions.Item label={<div style={{width: 200}}>有异议备注</div>} span={3}> <div className='c_error'  style={{width: 600}}>{selfTotalData.confirmReason}</div> </Descriptions.Item>
                        }
                        {
                            APINAME === 'company'&&
                            <>
                                {/*<Descriptions.Item label={<div style={{width: 200}}>操作记录</div>} span={3}><a onClick={getHandle} type='primary'>查看</a></Descriptions.Item>*/}
                                {/*<Descriptions.Item label={<div style={{width: 200}}>限额预警</div>} span={3}>*/}
                                    {/*<ol className='' style={{marginLeft: '-20px', width: 600}}>*/}
                                        {/*{*/}
                                            {/*selfTotalData.unusualMes && selfTotalData.unusualMes.map((item: string) => <li>{item}</li>)*/}
                                        {/*}*/}
                                    {/*</ol>*/}
                                {/*</Descriptions.Item>*/}
                            </>
                        }
                        {
                            !(selfTotalData.auditStatus === 40 && APINAME === 'customer') &&
                            <>
                                <Descriptions.Item label="本期总计应付款（元）" span={3}> <span>{ selfTotalData.lastMoney}</span> </Descriptions.Item>
                            </>
                        }
                        {
                            selfTotalData.showServer == 1 && APINAME === 'customer' &&
                            <>
                                <Descriptions.Item label="服务费折扣金额" span={1}> <span>{selfTotalData.serverFree}元</span> </Descriptions.Item>
                                {selfTotalData.freeReason && <Descriptions.Item label="服务费折扣原因" span={2}> <span>{selfTotalData.freeReason}</span> </Descriptions.Item>}
                            </>
                        }
                    </Descriptions>

                    <div className='c_flex c_flex_up c_flex_rl' style={{margin: '20px 0'}}>
                        <div>
                            <Search placeholder="输入姓名、手机号、身份证号、银行卡账号回车进行搜索" style={{width: 420}}
                                    onSearch={value => setAjaxData({...ajaxData, ...{keyword: value, page: 1}})} enterButton />
                        </div>
                        <div>
                            {
                                APINAME === 'company'&&
                                <>
                                    <Button className='c_mr_10' type={'primary'} onClick={getHandle}>操作记录</Button>
                                    <Button className='c_mr_10' type={'primary'}
                                            onClick={recalculation}
                                            disabled={(selfTotalData.auditStatus!==20 && selfTotalData.auditStatus !==10)? true: false}>重新计算</Button>
                                    <Button className='c_mr_10' type={'primary'}
                                            disabled={(selfTotalData.auditStatus!==20 && selfTotalData.auditStatus !==10)  ? true: false}
                                            onClick={() => setstatusForm({...statusForm, ...{flag: true, title: '审核'}})}>审核</Button>
                                </>
                            }
                            {
                                APINAME === 'company'&&
                                <>
                                    <Dropdown overlay={menu}>
                                        <Button type={'primary'} >导出 <DownOutlined /></Button>
                                    </Dropdown>
                                </>
                            }
                            {
                                !(selfTotalData.auditStatus === 40 ) && APINAME === 'customer' &&
                                <Button className='c_mr_10' type={'primary'}
                                        onClick={exportFile} disabled={exportFileLoading} >{!exportFileLoading ?'导出': '正在导出...'}</Button>
                            }
                            {
                                APINAME === 'customer' &&
                                <Button className='c_mr_10' type={'primary'}
                                        disabled={( (selfTotalData.auditStatus !==30) || selfTotalData.confirmStatus === 20)? true: false}
                                        onClick={() => setstatusForm({...statusForm, ...{flag: true, title: '确认对账单'}})}>确认对账单</Button>
                            }
                        </div>
                    </div>

                    {
                        APINAME === 'company'&&
                        <Descriptions title="" {...desOptions} className='c_mb_20'>
                            <Descriptions.Item label="应付服务费(元)" >{selfTotalData.termServerFee}</Descriptions.Item>
                            <Descriptions.Item label={<><div>服务费本期优惠金额（元）</div><div className='c_margin_middle'>服务费优惠历史扣减金额（元）</div></>} >
                                <div>{selfTotalData.disServerFee}</div>
                                <div className='c_margin_middle'>{selfTotalData.historyServerFee}</div>
                            </Descriptions.Item>
                            <Descriptions.Item label="服务费折扣金额" > <Input suffix={'元'} value={selfTotalData.serverFree} onChange={changeZhe} /></Descriptions.Item>
                            <Descriptions.Item label="折扣后服务费（元）" >{selfTotalData.lastServerFee}</Descriptions.Item>
                            <Descriptions.Item label="到款总额（元）" >{selfTotalData.totalMoney}</Descriptions.Item>
                            <Descriptions.Item label="折扣后到款总额（元）" >{selfTotalData.lastMoney}</Descriptions.Item>
                            <Descriptions.Item label="服务费折扣原因" span={2}>
                                <TextArea placeholder="请输入服务费折扣原因" {...op}
                                          value={selfTotalData.freeReason} onChange={onChangeReason1} />
                            </Descriptions.Item>
                            <Descriptions.Item label="客户端是否显示" ><div>
                                <Radio.Group options={plainOptions} onChange={onChangeReason} value={selfTotalData.showServer} />
                            </div></Descriptions.Item>
                        </Descriptions>
                    }

                    {
                        !((selfTotalData.auditStatus === 40 || selfTotalData.auditStatus === 20) && APINAME === 'customer') &&
                        <>
                            <Table
                                {...tableOptions} pagination={false}
                                loading={dataLoading}
                                columns={APINAME==='customer'? DataTitle.total_title_customer: total_title}
                                dataSource={[selfTotalData]}
                            />
                            <div style={{height: 40}}></div>
                        </>
                    }
                    <Table {...tableOptions}
                        loading={dataLoading}
                        columns={APINAME==='customer'? ((selfTotalData.auditStatus === 40 || selfTotalData.auditStatus == 20) ?
                            DataTitle.detail_title_customer_error : DataTitle.detail_title_customer ): DataTitle.detail_title}
                        dataSource={resData.page}
                        onChange={(data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}})}
                        pagination={{total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}}
                    />
                </>
            }


            <Modal title={statusForm.title} visible={statusForm.flag}
                   confirmLoading={statusForm.loading}
                   onOk={() => formSet.submit()} onCancel={() => setstatusForm({...statusForm, ...{flag: false}})}>
                {
                    statusForm.title === '确认对账单' &&
                    <div>
                        请对本次对账单进行确认，
                        {formSet.getFieldValue('status') === 20 && <div>如无异议请及时打款！</div>}
                        {formSet.getFieldValue('status') === 10 && <div>如有异议，点击确定后对账单将会返还客服人员，请您及时联系客服人员进行沟通!</div>}
                    </div>
                }
                <Form name="basic"
                    form={formSet}
                    onFinish={pushKaiPiao}
                    onFinishFailed={() => {}}
                >
                    {
                        statusForm.title === '确认对账单' &&
                        <Form.Item label="" name="status" rules={[{ required: true, message: '请选择确认状态' }]}>
                            <Radio.Group onChange={() => setMockdata(mockData+1)}>
                                <Radio value={20}>无异议</Radio>
                                <Radio value={10}>有异议，需复议</Radio>
                            </Radio.Group>
                        </Form.Item>
                    }
                    {
                        statusForm.title === '审核' &&
                        <>
                            <Form.Item label="" name="status" rules={[{ required: true, message: '请选择审核状态' }]}>
                                <Radio.Group onChange={() => setMockdata(mockData+1)}>
                                    <Radio value={10}>通过</Radio>
                                    <Radio value={20}>驳回</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <div className="c_mb_20">
                                审核后，对账单将同步发送至客户的{selfTotalData.email}邮箱确认
                            </div>
                            <Form.Item label="" name="resonse" rules={[{ required: formSet.getFieldValue('status') === 20? true: false}]}>
                                <TextArea placeholder="可增加备注一同发送给客户"  style={{resize: 'none'}} {...textareaprop} />
                            </Form.Item>
                        </>
                    }
                    {
                        formSet.getFieldValue('status') === 20 && statusForm.title === '审核' && APINAME === 'customer' &&
                        <Form.Item label="" name="resonse" rules={[{ required: true}]}>
                            <TextArea placeholder="请输入驳回原因"  style={{resize: 'none'}} {...textareaprop} />
                        </Form.Item>
                    }
                </Form>

            </Modal>

            <Drawer
                width={540}
                title={opeData.title}
                placement="right"
                closable={false}
                onClose={() => {setopeData({flag: false, title: ''})}}
                visible={opeData.flag}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={handleList}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={<a>{item.handleTime + '【' + item.handleName + '】' + (item.handleStatus===10? '修改': item.handleStatus===20? '删除': item.handleStatus===30? '操作': '') + '对账单'}</a>}
                                description={item.handleDetail}
                            />
                        </List.Item>
                    )}
                />,
            </Drawer>

            {
                loading &&
                <Spin tip="Loading...">
                    <Alert
                        message="加载中..."
                        description="正在计算中，请稍后..."
                        type="info"
                    />
                </Spin>
            }

        </div>
    );
}

const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig,
        pageSizeOptions: state.golbal.pageSizeOptions,
        member_list: state.index.member_list,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(ManageDetail)


