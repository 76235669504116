import React, {useEffect} from 'react';
import { withRouter} from "react-router-dom";
import {  Menu } from 'antd';
import {connect} from 'react-redux';


// import {routesArr} from '../router';
import { Dispatch } from "redux";
import { changeMenuInfo } from "../store/model/reducers/golbal";

const { SubMenu } = Menu;

function getItem(arr: any) {
    return arr.map((item: any) => {
        // console.log(item)
        if(item.hidden) return null
        if(item.routes && item.routes.length>=1 && item.icon) {
            return <SubMenu key={item.path} title={ <> {item.icon} {item.title}</> }>
                {getItem(item.routes)}
            </SubMenu>
        }

        if(!item.icon && item.routes) {
          return getItem(item.routes)
        }

        if(item.redirect) return null

        return <Menu.Item key={item.path}>
                {item.icon}
                {item.title}
            </Menu.Item>

    })
}

interface InterfaceMenu {
  openKey:  string[],
  selKey: string[],
}

function App(props: any) {
    useEffect(() => {
      if(props.location && props.change_menu_info) {
          const pathname = props.location.pathname
          const pathArr = pathname.split('/')
          const openKey = [pathArr[0], pathArr[1], pathArr[2]].join('/')
          // console.log(props.location, openKey, pathArr)
          props.change_menu_info({...props.menuInfo, ...{selKey: pathname, openKey: openKey}})
      }
    }, [props.location.pathname])

    const clickNav = (item: any) => {
      props.history.push(item.key)
    }

    const clickSub = (item: any) => {
      props.change_menu_info({...props.menuInfo, ...{openKey: item}})
    }

    return (
        <Menu
            openKeys={props.menuInfo.openKey}
            selectedKeys={props.menuInfo.selKey}
            mode="inline"
            theme="light"
            onClick={clickNav}
            onOpenChange={clickSub}
            style={ {width: '256px', height: '100%', zIndex: 1, position: 'fixed',paddingTop: 60} }
        >
            {getItem(props.routesArr|| [])}
        </Menu>
    );
}

const mapStateProps = (state: any) => {
  return {
    menuInfo: state.golbal.menuInfo
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    change_menu_info: (data: InterfaceMenu ) => {

      dispatch(changeMenuInfo(data))
    }
  }
};

export default connect(mapStateProps, mapDispatchToProps )(withRouter(App));
