import React, {useEffect, useState} from "react";
import {
    Button, Drawer,
    Form, Input,
    Modal,
    Select,
    Table,
     message
} from 'antd';

// import {TreeDepartment} from "@/components/select";
import {Dispatch} from "redux";
import {api_index, mock_table} from "@/store/model/reducers/index";
import {connect} from "react-redux";
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';
// import InputComponent from '@/components/inputBox/index'

import {RouteComponentProps} from "react-router";
import {ajax} from "@/store/midPromise";

const { confirm } = Modal;

interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    typeArr: any,
    get_mock: () => void,
    api_all: (name:string, data: API.InterfaceData) => void,
}


const { Search } = Input;
const { Option } = Select;

// const APINAME = windowObj.localStorage.getItem('apiName')
const windowObj = global.windows || window;

const Product:React.FC<Interfaceprops> = (props) => {

    const [resData, setresData] = useState({total: '', page: [], loading: true})
    const [opeData, setopeData] = useState({flag: false, title: '', id: ''})
    const [ajaxData, setAjaxData] = useState({
        productType: '',
        productStatus: '',
        productName: '',
        page: 1,
        rows: 10
    })

    const [formSet] = Form.useForm();

    const edit = (data: any) => {
        setopeData({flag: true, title: '编辑', id: data.id})
        formSet.setFieldsValue( {...data} );
    }

    const formPush = (data: any) => {
        if(opeData.title === '新增产品') {
            ajax.post(`/api/Non-eeds/admin/business/product`, data).then((res: any) => {
                if(res && res.code === 200) {
                    setopeData({...opeData, ...{flag: false, title: ''}})
                    message.success('成功')
                    init()
                }
            })
        }
        if (opeData.title === '编辑') {
            ajax.put(`/api/Non-eeds/admin/business/product/${opeData.id}`, data).then((res: any) => {
                if(res && res.code === 200) {
                    setopeData({...opeData, ...{flag: false, title: ''}})
                    message.success('成功')
                    init()
                }
            })
        }

    }
    const changeStatus = (data: any) => {
        const text = windowObj.GLOBAL_LIBS.getStatus(data.productStatus, true, true)
        const status = windowObj.GLOBAL_LIBS.getStatus(data.productStatus, false, true)
        confirm({
            title: `确认要对该产品进行【${text}】吗？`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk() {
                ajax.put(`/api/Non-eeds/admin/business/product/${data.id}/status`, {status}).then((res: any) => {
                    if(res && res.code === 200) {
                        message.success('成功')
                        init()
                    }
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    const init = () => {
        ajax.get(`/api/Non-eeds/admin/business/product`, {params: ajaxData}).then((res: any) => {
            setresData({...resData, ...{loading: false}})
            if(res && res.code === 200) setresData({...res.data})
        })
    }
    useEffect (() => {
        init()
    }, [ajaxData])

    const columns = [
        {
            title: '产品名称',
            dataIndex: 'productName',
            width: 220,
        },
        {
            title: '产品类型',
            dataIndex: 'productType',
            width: 120,
            render: (data:any) => <div>{Number(data)===1? '雇用经济': Number(data)===2? '非雇用经济': 'SaaS售卖' }</div>
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            width: 100,
        },
        {
            title: '产品状态',
            dataIndex: 'productStatus',
            width: 180,
            render: (data:any) => <div>{data==='20'? '已停用': '已启用' }</div>
        },
        {
            title: '操作',
            width: 180,
            fixed: "right" as "right",
            render: (data: any) => <>
                <Button type={'link'} size={'small'} onClick={() => edit(data)}>编辑</Button>
                <Button type={'link'} size={'small'} onClick={() =>  changeStatus(data) }>
                    {windowObj.GLOBAL_LIBS.getStatus(data.productStatus, true, true)}
                </Button>
            </>
        },
    ];
    const layout = {
        labelCol: { span: 8},
        wrapperCol: { span: 18 },
        onFinish: formPush,
        form: formSet
    };
    const tableOptions = {
        rowKey: 'id',
        scroll:{x: 'max-content', y: 540},
        loading: resData.loading,
        columns: columns,
        dataSource: resData.page,
        onChange: (data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }

    return (
        <div className="content-page">

            <div className='c_flex c_flex_up c_flex_rl' style={{marginBottom: 20}}>
                <div>
                    <Search placeholder="输入产品名称搜索" style={{width: 320}}
                            onSearch={value => setAjaxData({...ajaxData, ...{productName: value, page: 1}})} enterButton/>
                    <span className='c_ml_20'>产品类型:</span>
                    <Select defaultValue="0" style={{ width: 120, marginLeft: 20 }}
                            onChange={(value) => setAjaxData({...ajaxData, ...{productType: value !=='0'? value: '', page: 1}})}>
                        <Option value={'0'} >全部</Option>
                        {
                            props.typeArr.product_type.map((item: any) => {
                                return <Option value={item.value} key={item.value} >{item.name}</Option>
                            })
                        }
                    </Select>
                    <span className='c_ml_20'>产品状态:</span>
                    <Select defaultValue="0" style={{ width: 120, marginLeft: 20 }}
                            onChange={(value) => setAjaxData({...ajaxData, ...{productStatus: value !=='0'? value: '', page: 1}})}>
                        <Option value={'0'} >全部</Option>
                        {
                            props.typeArr.product_status.map((item: any) => {
                                return  <Option value={item.value} key={item.value} >{item.name}</Option>
                            })
                        }
                    </Select>
                </div>

                <Button type={'primary'} onClick={() => setopeData({...opeData, ...{flag: true, title: '新增产品'}})}>新增</Button>
            </div>

            <Table {...tableOptions}/>

            <Drawer
                width={540}
                title={opeData.title}
                placement="right"
                closable={false}
                onClose={() => {setopeData({...opeData, ...{flag: false, title: ''}})}}
                visible={opeData.flag}
            >
                <Form {...layout}>
                    <Form.Item
                        label="产品名称"
                        name="productName"
                        rules={[{ required: true}]}
                    >
                        <Input placeholder='请输入'  />
                    </Form.Item>
                    <Form.Item
                        label="产品类型"
                        name="productType"
                        rules={[{ required: true}]}
                    >
                        <Select>
                            {
                                props.typeArr.product_type.map((item: any) => {
                                    return <Option value={Number(item.value)} key={item.value} >{item.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remarks"
                    >
                        <Input.TextArea placeholder="请输入" autoSize style={{resize: 'none'}} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
}

const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig,
        pageSizeOptions: state.golbal.pageSizeOptions,
        member_list: state.index.member_list,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get_mock: () => {
            dispatch(mock_table())
        },
        api_all: (name:string, data: API.InterfaceData, ) => {
            dispatch(api_index({...data})(name))
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(Product);

