import axios from 'axios';
import {notification} from "antd";

axios.defaults.withCredentials = true;

const windowObj = global.windows || window;

function getCookie(name: string) {
    var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");
    if(arr=document.cookie.match(reg))
        return unescape(arr[2]);
    else
        return null;
}

const login = () => windowObj.location.href = '/'
// 取消请求
// let preTime = Date.now()
// const setTime = () => {
//     const nowTime = Date.now()
//     if(nowTime - preTime > 60000 * 30) {
//         notification['error']({
//             message: '失败',
//             description: '长时间未操作，请重新登录',
//             onClose: login
//         });
//         localStorage.setItem('token', '')
//         setTimeout(login, 3000)
//         source.cancel('长时间未操作')
//     } else {
//         preTime = nowTime
//     }
// }
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const Ajax = axios.create({
    cancelToken: source.token
})
Ajax.interceptors.request.use(
    config => {
        // setTime()
        // const token = localStorage.getItem('token')
        const token = getCookie('LLY_TOKEN')
        if (token) config.headers.authorization = token
        return config
    },
    err => onRejected(err))

Ajax.interceptors.response.use(res => {
    // console.log(res)
    const [errorCode, errorMsg, data] = [res.data.code, res.data.message, res.data];
    if (Object.prototype.toString.call(data) !== '[object Blob]') {
        switch (Number(errorCode)) {
            case 200: //成功响应
                return data
                break;
            default: // 默认响应失败
                notification['error']({
                    message: '失败',
                    description: errorMsg
                });
                return {}
        }
    } else {
        return res
    }
}, err => onRejected(err))


const onRejected = (error: any) => {
    // console.log(JSON.stringify(error))
    if (!error.response) return
    switch (Number(error.response.status)) {
        case 401: // 登录信息过期
            notification['error']({
                message: '失败',
                description: '登录信息过期，请重新登录',
                onClose: login
            });
            setTimeout(login, 3000)
            return {}
            break;
        default: // 默认响应失败
            notification['error']({
                message: '失败',
                description: '网络异常，请稍后再试...'
            });
            return {}
    }
}

export const ajax = Ajax

export default (store: any) => (next: any) => (action: any) => {
    const {dispatch, getState} = store;
    // console.log(action)
    if (typeof action === 'function') {
        action(dispatch, getState);
        return;
    }

    const {
        promise,
        type,
        ...rest
    } = action;
    if (!action.promise) {
        return new Promise(function (resolve, reject) {
            resolve(next(action));
        })
    }

    next({
        ...rest,
        type: `begin-${type}`
    })

    const onFulfilled = (result: any ) => {
        // console.log(result)
        if (result && result.data ) {
            if (action.succ && Number(result.code) === 200) action.succ(result.data)
            next({
                ...rest,
                result,
                type: type
            })
        }
        if (action.overFn) action.overFn()
    }
    return promise(Ajax)
        .then(onFulfilled, onRejected)
        .catch((error: any) => {
            onRejected(error)
        })
}
