import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router
} from "react-router-dom";
import {Provider} from 'react-redux'
import { ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';

import './statics/css/index.less';

// import oldStore from "@/store";
import store from "@/store";
// import { PersistGate } from 'redux-persist/integration/react'

import getRouter from "./router";
import * as GLOBAL_LIBS from '@/statics/js/libs'

const windowObj = global.windows || window;

// process.env.NODE_ENV === 'development' && require('./mock/index'); // 引入mockjs

windowObj.GLOBAL_LIBS = GLOBAL_LIBS
// console.log(windowObj)

ReactDOM.render(
    <Provider store={store}>
        {/*<PersistGate loading={null} persistor={persistor}>*/}
            <ConfigProvider {...{locale: zhCN}}>
                <Router basename={''}>
                    {getRouter()}
                 </Router>
            </ConfigProvider>
        {/*</PersistGate>*/}
        <div id='subapp-container'></div>
    </Provider>
,
  document.getElementById('root')
);
