import React, {useEffect} from 'react';

import RouterBox from '../components/routerFn'

import Header from '../components/header'
import Menulayout from '../components/menu'

import './less/mainApp.less'
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux";
import {customer_info, public_info} from "@/store/model/reducers/golbal";
import {connect} from "react-redux";

interface Interfaceprops extends RouteComponentProps {
    routes: any,
    get_public: () => void,
    get_customer_info: () => void,
}

const MainApp:React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')

    const {routes} = props;
  //   let title2 = ''
  // const com = (arr: any, path: string) => {
  //     for (const element of arr){
  //         if (element.path === path) {
  //             // console.log(element.title)
  //             title2 = element.title
  //         }
  //         if(element.routes) com(element.routes, path)
  //     }
  //     return title2
  // }
  // const [title, setTitle] = useState('')

  useEffect(() => {
    //   console.log(com(routes, props.location.pathname))
    //   setTitle(com(routes, props.location.pathname))
      props.get_public()
      if (APINAME === 'customer') props.get_customer_info()
  }, [])

  return (
    <div className="c_flex c_flex_col" style={{height: '100%', background: '#efefef'}}>
      {/*header*/}
      <Header />
      <div className={'c_flex'} style={{height: '100%'}}>
        {/*菜单栏*/}
        <Menulayout routesArr={routes} />
        <div id='page_content' style={{width: '100%',overflow: 'auto',boxSizing: 'border-box', position: 'absolute',paddingLeft: 256,height: '100%', paddingTop: 60}}>
            {/*position: 'absolute', paddingLeft: '256px',*/}
            {/*<div className='global-content-header'>*/}
            {/*<h4>{title}</h4>*/}
            {/*<div>{}</div>*/}
          {/*</div>*/}

          <div style={{margin: '30px', flexGrow: 1}}>
            {/*页面挂载*/}
              <RouterBox routers={routes} />
            <div id='subapp-container'></div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateProps = (state: any) => {
    return {
        publicInfo: state.golbal.publicInfo,
        // registerInfo: state.user.register,
        // username:state.index.username,
        // password:state.index.password
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get_public: () => {
            dispatch(public_info())
        },
        get_customer_info: () => {
            dispatch(customer_info())
        },
    }
};

export default connect(mapStateProps, mapDispatchToProps)(MainApp)
