import React, {useEffect, useState} from "react";
import {
    Select, Descriptions
} from 'antd';

import {
    // PlusOutlined
} from '@ant-design/icons';
import {ajax} from "@/store/midPromise";

const {Option} = Select

interface InterfaceCase {
    id: string,
    name: string,
    invoiceSpecial?: number,
    invoiceGeneral?: number,
}

export const CaseTax = (props: any) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')

    const [caseList, setcaseList] = useState<InterfaceCase[]>([])
    const [resData, setresData] = useState({
        productName: '',
        taxSourceName: '',
        incomWarnning: 0,
        warns: {
            second: '',
            day: '',
            week: '',
            month: '',
            season: '',
            year: ''
        },
        supplierName: ''
    })

    const changeTax = (data: any) => {
        // console.log(data)
        props.onChange(data)
    }
    const initCaseList = (data: any) => {
        const list = []
        if(data.invoiceSpecial) list.push({id: 'invoiceSpecial', name: '增值税专用发票、' + data.invoiceSpecial + '%'})
        if(data.invoiceGeneral) list.push({id: 'invoiceGeneral', name: '增值税普通发票、' + data.invoiceGeneral + '%'})
        // console.log(list)
        setcaseList([...list])
    }

    // useEffect(() => {
    //     console.log( props.data)
    //     const list = []
    //     if(props.data.invoiceSpecial) list.push({id: 'invoiceSpecial', name: '增值税专用发票、' + props.data.invoiceSpecial + '%'})
    //     if(props.data.invoiceGeneral) list.push({id: 'invoiceGeneral', name: '增值税普通发票、' + props.data.invoiceGeneral + '%'})
    //     setcaseList([...list])
    // }, [props.data])

    const init = () => {
        // console.log(props.case_id)
        // console.log(props.getForm.getFieldValue('invoiceType'))

        ajax.get(`/api/Non-eeds/reception/${APINAME}/scheme/${props.case_id}`).then((res: any) => {
            if(res && res.code === 200) {
                setresData(res.data)
                initCaseList(res.data)
            }
        })
    }

    useEffect(() => {
        init()
    }, [props.case_id])

    return(
        <>
            <Descriptions title="" bordered layout="vertical">
                <Descriptions.Item label="关联产品">{resData.productName}</Descriptions.Item>
                <Descriptions.Item label="税源地">{resData.taxSourceName}</Descriptions.Item>
                <Descriptions.Item label="发票种类&税率">
                    {
                        <Select onChange={changeTax} value={props.getForm.getFieldValue('invoiceType')} style={{width: 200}} >
                            {
                                caseList.map((item: any) => {
                                    return  <Option value={item.id} key={item.id} >{item.name}</Option>
                                })
                            }
                        </Select>
                    }
                </Descriptions.Item>
                <Descriptions.Item label="个人收入额预警">{resData.incomWarnning===10? '是': '否'}</Descriptions.Item>
                {
                    resData.incomWarnning===10 && resData.warns &&
                    <Descriptions.Item label="预警详情">
                        {Number(resData.warns.second)>=0 && <div>单笔、{resData.warns.second}万元</div>}
                        {Number(resData.warns.day)>=0 && <div>每日、{resData.warns.day}万元</div>}
                        {Number(resData.warns.week)>=0 && <div>单周、{resData.warns.week}万元</div>}
                        {Number(resData.warns.month)>=0 && <div>单月、{resData.warns.month}万元</div>}
                        {Number(resData.warns.season)>=0 && <div>单季、{resData.warns.season}万元</div>}
                        {Number(resData.warns.year)>=0 && <div>单年、{resData.warns.year}万元</div>}
                    </Descriptions.Item>
                }
                <Descriptions.Item label="交付主体">{resData.supplierName}</Descriptions.Item>
            </Descriptions>
        </>
    )
}
