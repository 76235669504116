import { Route, Switch } from "react-router-dom";
import React from "react";
import * as GLOBAL_LIBS from '@/statics/js/libs'

function RouteWithSubRoutes(route: any) {
  return (
    <Route
      key={route.path}
      path={route.path}
      exact={route.exact}
      render={props => {
        // pass the sub-routes down to keep nesting
        // console.log(props)
        return <route.component {...props} routes={route.routes} LIBS={GLOBAL_LIBS} />
      }}
    />
  );
}

function Layout(props: any) {
  // console.log(props)
  return (
    <React.Fragment>
      {props.children}
      <Switch>
        {props.routers && props.routers.map((item: any, i: any) => {
          // @ts-ignore
          return <RouteWithSubRoutes key={i} {...item} />
        })}
      </Switch>
    </React.Fragment>
  );
}

export default Layout;
