import React, {useEffect, useState} from "react";
import {
    Button,
    Form, Input,
    Modal,
    Select,
    Table,
    DatePicker, message, Drawer, Upload
} from 'antd';

import {Dispatch} from "redux";
import {get_tracking_list} from "@/store/model/reducers/golbal";
import {connect} from "react-redux";
import {
    UploadOutlined
} from '@ant-design/icons';

import {RouteComponentProps} from "react-router";
import {ajax} from "@/store/midPromise";
import moment, {Moment} from 'moment';
import {downFile, getHeaderValue} from "@/statics/js/libs";

const {Option} = Select
const { Dragger } = Upload;

interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    companyEmail: string,
    customer_info: any,
    typeArr: any,
    get_tracking: () => void,
    tracking_list: [{id: number, expressName: string}]
}

interface InterfaceAjax {
    keyword: string,
    date:  null | Moment | undefined | string ,
    page: number,
    rows: number
}

const { Search } = Input;

let billID = ''

const Manage:React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')

    const [resData, setresData] = useState({loading: true, total: '', page: []})
    const [opeData, setopeData] = useState({flag: false, data: {id: '', email: ''}, loading: false})
    const [ajaxData, setAjaxData] = useState<InterfaceAjax>({
        keyword: '',
        date:  null,
        page: 1,
        rows: 10
    })
    const [fileList, setfileList] = useState<Array<any>>([])
    const [acData, setacData] = useState({flag: false, loading: false})

    const goDetail = (data: any) => {
        // console.log(data)
        if (APINAME === 'company') {
            props.history.push(`/lingGong_B/bill/manage_detail?id=${data.id}`)
        }
        if (APINAME === 'customer') {
            props.history.push(`/lingGong_C/bill/manage_detail?id=${data.id}`)
        }
    }
    const onMouthChange = (data:any) => {
        // console.log(data)
        data && setAjaxData({...ajaxData, ...{date: moment(data).format('YYYY-MM')}})
    }

    const openKaipiao = (data: any) => {
        setopeData({...opeData, ...{flag: true, data: data}});
        // console.log(billID)
        props.get_tracking()

    }
    const pushKaiPiao = (data: any) => {
        setopeData({...opeData, ...{loading: true}})
        ajax.put(`/api/Non-eeds/check/company/invoiceStatus/${opeData.data.id}/invoiceStatus`, data).then((res: any) => {
            if(res && res.code === 200) {
                message.success('成功')
                init()
            }
            setopeData({...opeData, ...{flag: false, loading: false}})
        })
    }

    const uploadAsFile = (data: any) => {
        billID = data.id
        setacData({...acData, ...{flag: true}})
    }
    const downloadAs = (data: any) => {
        ajax.get(`/api/Non-eeds/aliyunOss/${APINAME}/fileDownload/${data.id}`, {responseType: 'blob'}).then((res: any) => {
            if(res.headers){
                const name = getHeaderValue(res.headers['content-disposition'], 'filename')
                downFile(res.data, name)
            }
        })
    }
    const handleChange = (info: any) => {
        const { file, onProgress, onSuccess, onError } = info;
        // console.log(file)
        if(file.size > 1048576 * 20) {
            message.error('上传文件不能大于20M')
            onError('上传文件不能大于20M')
            return
        }
        setTimeout(() => {
            onSuccess(file);
            setfileList([file]);
        }, 1000)

    }
    const surePushAs = async () => {
        const formData = new FormData()
        formData.append('file', fileList[0])
        formData.append('batchId', billID)
        setacData({...acData, ...{loading: true}})
        const result = await ajax.post(`/api/Non-eeds/aliyunOss/${APINAME}/upload`, formData)
        setacData({...acData, ...{loading: false, flag: false}})
    }

    const [formSet] = Form.useForm();
    const init = () => {
        ajax.get(`/api/Non-eeds/check/${APINAME}/bill`, {params: ajaxData}).then((res: any) => {
            setresData({...resData, ...{loading: false}})
            if(res && res.code === 200) setresData({...res.data})
        })
    }
    useEffect (() => {
        init()
    }, [ajaxData])

    const getText = (status: number) => {
        if (status === 20) return APINAME==='customer' ? '正在审核': '未审核'
        switch (status) {
            case 10: return '复议'; break;
            case 30: return '通过'; break;
            case 40: return '驳回'; break;
        }
    }
    const getTrank = (str: string) => {
        ajax.post(`/api/Non-eeds/check/${APINAME}/express`, {trackingNumber: str}).then((res: any) => {
            if(res && res.code === 200) {
                windowObj.open(res.data.trackingPath)
            }
        })
        // if (!str) return ''
        // const startNum = str.slice(0, 2)
        // console.log(startNum)
        // if (startNum === 'sf' || startNum === 'SF') {
        //     return `https://h5.m.taobao.com/guoguo/app-guoguo-detail-cp/index.html?mailNo=${str}&cpCode=sf#/`
        // }
        // return `https://www.baidu.com/s?wd=${str}`
    }

    const columns:Array<any> = [
        {
            title: '提交时间',
            dataIndex: 'createTime',
            width: 120,
        },
        {
            title: '对账单周期',
            dataIndex: 'billCycle',
            width: 120,
            render: (data: any) => <div>{JSON.parse(data)[0]+ '～' + JSON.parse(data)[1]}</div>
        },
        {
            title: '对账单人数（人）',
            dataIndex: 'billPerson',
            width: 180,
        },
        {
            title: '到款总额（元）',
            dataIndex: 'lastMoney',
            width: 150,
        },
        {
            title: '审核状态',
            width: 120,
            render: (data: any) => <>
                <div>{getText(data.auditStatus)}</div>
                {(data.auditStatus===30 || data.auditStatus===40)&& <div>{data.auditTime}</div>}
            </>
        },
        {
            title: '确认状态',
            width: 120,
            render: (data: any) => <>
                <div>{data.confirmStatus===10? '未确认': '已确认'}</div>
                {data.confirmStatus===20&& <div>{data.confirmTime}</div>}
            </>
        },
        {
            title: '相关文件',
            width: 100,
            render: (data: any) => <>
                {data.confirmStatus!==20 ? '--': data.uploadStatus === 0 ? '未上传' : <Button type="link" onClick={() => downloadAs(data)}>下载</Button>}
            </>
        },
        {
            title: '发票状态',
            width: 100,
            render: (data: any) => <>
                <div>{Number(data.invoiceStatus)===10? '未开票': '已开票'}</div>
                {data.invoiceStatus===20&& <div>{data.invoiceTime}</div>}
            </>
        },
        {
            title: '快递单号',
            dataIndex: 'trackingNumber',
            width: 100,
            render: (data: string) => <a onClick={() => getTrank(data)} target='_blank'>{data}</a>
        },
    ];

    if(APINAME === 'customer'){
        columns.push({
            title: '操作',
            width: 180,
            fixed: "right",
            render: (data: any) => <>
                <Button type={'link'} size={'small'} onClick={() =>  goDetail(data) }>{(data.auditStatus ===30 && data.confirmStatus===10)? '确认对账单': '查看'}</Button>
            </>
        })
    }
    if(APINAME === 'company') {
        columns.unshift({
            title: '客户名称',
            dataIndex: 'customerName',
            width: 160,
        })
        columns.push({
            title: '操作',
            width: 180,
            fixed: "right",
            render: (data: any) => <>
                {
                    Number(data.invoiceStatus) === 10 && data.confirmStatus === 20 &&
                    <Button type={'link'} size={'small'} onClick={() => openKaipiao(data)}>标记开票</Button>
                }
                {
                    data.invoiceStatus !== 10 &&
                    <Button type={'link'} size={'small'} onClick={() =>  goDetail(data) }>{
                        (data.auditStatus === 20 && '审核')}&nbsp;&nbsp; 详情</Button>
                }
                {
                    // data.confirmStatus === 20 &&
                    <Button type={'link'} size={'small'} onClick={() =>  uploadAsFile(data) }>上传文件</Button>
                }
            </>
        })
    }

    const tableOptions = {
        rowKey: 'id',
        scroll:{x: 'max-content', y: 540},
        loading: resData.loading,
        columns: columns,
        dataSource: resData.page,
        onChange: (data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }
    const uploads = {
        name: 'file',
        fileList: fileList,
        onRemove: () => {
            setfileList([]);
        }
    }

    return (
        <div className="content-page">
            <div className='c_flex c_flex_up c_flex_rl' style={{marginBottom: 20}}>
                <div>
                    {
                        APINAME === 'company' &&
                        <Search placeholder="请输入客户名称" style={{width: 320, marginRight:20}}
                                value={ajaxData.keyword} onChange={(e) => setAjaxData({...ajaxData, ...{keyword: e.target.value}})}
                                onSearch={value => setAjaxData({...ajaxData, ...{keyword: value, page: 1}})} enterButton />
                    }
                    <DatePicker style={{marginRight:20, width: 200}}
                                onChange={onMouthChange} picker="month" placeholder={'请选择提交时间'} />
                    {/*{*/}
                        {/*APINAME === 'company' &&*/}
                        {/*<Button type="primary" onClick={resetSearch}>重置</Button>*/}
                    {/*}*/}
                </div>
            </div>

            <Table {...tableOptions} />

            <Modal
                title="已开票，录入快递单号"
                visible={opeData.flag}
                confirmLoading={opeData.loading}
                onOk={() => formSet.submit()}
                onCancel={() => setopeData({...opeData, ...{flag: false}})}
            >
                <Form
                    name="basic"
                    form={formSet}
                    initialValues={{ name: '1' }}
                    onFinish={pushKaiPiao}
                    onFinishFailed={() => {}}
                >
                    <Form.Item label="" name="trackingId" rules={[{ required: true }]}>
                        <Select>
                            {
                                props.tracking_list.map((item) =>{
                                    return  <Option value={item.id} key={item.id}>{item.expressName}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item label="" name="trackingNumber" rules={[{ required: true}]}>
                        <Input placeholder={'输入快递单号'} />
                    </Form.Item>
                </Form>
                <div>
                    点击确定后，将通过{opeData.data.email}邮箱通知客户
                </div>
            </Modal>

            <Drawer
                width={540}
                title={'上传相关文件'}
                placement="right"
                onClose={() => {setacData({...acData, ...{flag: false}})}}
                visible={acData.flag}
            >
                <div>
                    <p className={'c_error'}>警告：该批次已经有相关文件存在，如继续上传则将替换原文件。</p>
                    <p className={'c_title'}>请上传与该批次相关的文件</p>
                    <Dragger {...uploads} customRequest={handleChange} >
                        <p className="ant-upload-drag-icon"><UploadOutlined /></p>
                        <p className="ant-upload-text">点击或拖拽进行上传</p>
                        <p className="ant-upload-hint">最大支持20M</p>
                    </Dragger>
                </div>

                <Button type="primary" loading={acData.loading} className='c_mt_20'
                        disabled={fileList.length > 0 ? false: true}
                        onClick={surePushAs}>确定</Button>

            </Drawer>
        </div>
    );
}

const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig,
        pageSizeOptions: state.golbal.pageSizeOptions,
        companyEmail: state.golbal.companyEmail,
        member_list: state.index.member_list,
        customer_info: state.golbal.customer_info,
        tracking_list: state.golbal.tracking_list
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get_tracking: () => {
            dispatch(get_tracking_list())
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(Manage)


