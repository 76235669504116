import React, {useEffect, useState} from "react";
import {Radio} from "antd";



const PayTTT = (props: any) => {
    const fzList = [
        {
            id: 10,
            value: '个人承担'
        },
        {
            id: 20,
            value: '企业承担'
        }
    ]

    const [value, setvalue] = useState(0)

    const onChange = (data:any) => {
        setvalue(data)
        props.onChange && props.onChange(data)
    }

    useEffect(() => {
        setvalue(props.value)
        // console.log(props.case_id)
    }, [props.value])

    return (
        <Radio.Group disabled={props.case_id? true: false}
                     value = {props.case_id ? 20: value}
                     onChange = {onChange}  >
            {
                fzList.map((item) =>
                    <Radio value={item.id} key={item.id}
                    >{item.value}</Radio>)}
        </Radio.Group>
    )
}

export default PayTTT
