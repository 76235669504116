// 企业信息
import React, {useEffect, useState} from "react";
import {
    Button, Checkbox, Drawer,
    Form, Input,
    Modal,
    Select,
    Table,
    Radio, DatePicker, Descriptions, message
} from 'antd';

import {TreeDepartment} from "@/components/select";
import {Dispatch} from "redux";
import {api_index, mock_table} from "@/store/model/reducers/index";
import {connect} from "react-redux";
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';

import {RouteComponentProps} from "react-router";
import {ajax} from '@/store/midPromise'

const { confirm } = Modal;

interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    typeArr: any,
    get_mock: () => void,
    get_mock2: (data: API.InterfaceData) => void,
    publicInfo: any
}


const Enterprise:React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')

    const [resData, setresData] = useState( {
        customerName: '',
        creditCode: '',
        legalPerson: '',
        customerAddress: '',
        customerPhone: '',
        receivePhone: '',
        bank: '',
        bankCard: '',
        receiveName: '',
        receiveAddress: '',
        customerMail: '',
    })
    const [opeData, setopeData] = useState(false)

    const [formEmail] = Form.useForm()
    const modifyEmail = (data: any) => {
        console.log(data)
        ajax.put(`/api/Non-eeds/reception/${APINAME}/${props.publicInfo.companyId}/mail`, data).then((res: any) => {
            if(res && res.code === 200) {
                setopeData(false)
                message.success('成功')
                init()
            }
        })
    }
    const changeValue = (e: any, name: string) => {
        const obj = {
            [name]: e.target.value
        }
        setresData({...resData, ...obj})
    }
    const testValue = () => {
        if(!resData.bank) {
            message.error('请输入开户行')
            return false
        }
        if(resData.bank.length < 2) {
            message.error('开户行最少2个汉字，最多50个汉字')
            return false
        }
        if(!resData.bankCard) {
            message.error('请输入银行账号')
            return false
        }
        if(resData.bankCard.length < 10) {
            message.error('银行账号格式有误')
            return false
        }
        if(!resData.receiveName) {
            message.error('请输入发票接收联系人')
            return false
        }

        if(resData.receiveName.length < 2) {
            message.error('发票接收联系人最少两个汉字，最多20个汉字')
            return false
        }
        if(resData.receivePhone === '') {
            message.error('请输入联系人手机号')
            return false
        }
        if(resData.receiveAddress === '') {
            message.error('请输入发票接收地址')
            return false
        }
        if(resData.customerMail === '') {
            message.error('请输入邮箱')
            return false
        }
        return true
    }
    const sureModify = () => {
        // console.log(resData)
        if(testValue()) {
            ajax.put(`/api/Non-eeds/reception/${APINAME}/${props.publicInfo.companyId}`, resData).then((res: any) => {
                if(res && res.code === 200) {
                    message.success('修改成功')
                    setopeData(false)
                }
            })
        }
    }

    const init = () => {
        ajax.get(`/api/Non-eeds/reception/${APINAME}/${props.publicInfo.companyId}`).then((res: any) => {
            if(res && res.code === 200) setresData(res.data)
        })
    }
    useEffect (() => {
        if (props.publicInfo.companyId) init()
    }, [props.publicInfo.companyId])

    const list = [
        {
            id: 0,
            label: <div style={{width: 160}}>发票抬头（公司名称）</div>,
            value: 'customerName',
        },
        {
            id: 1,
            label: <div style={{width: 160}}>统一信用代码</div>,
            value: 'creditCode'
        },
        {
            id: 2,
            label: <div style={{width: 160}}>法人</div>,
            value: 'legalPerson'
        },
        {
            id: 3,
            label: <div style={{width: 160}}>公司地址</div>,
            value: 'customerAddress'
        },
        {
            id: 4,
            label: <div style={{width: 160}}>联系电话</div>,
            value: 'customerPhone'
        },
        {
            id: 5,
            label: <div style={{width: 160}}>开户行</div>,
            value: 'bank',
            modify: true,
            maxLength: 50,
        },
        {
            id: 6,
            label: <div style={{width: 160}}>银行账号</div>,
            value: 'bankCard',
            modify: true,
            maxLength: 25,
            type: 'number'
        },
        {
            id: 7,
            label: <div style={{width: 160}}>发票接收联系人</div>,
            value: 'receiveName',
            modify: true,
            maxLength: 20,
        },
        {
            id: 8,
            label: <div style={{width: 160}}>联系人电话</div>,
            value: 'receivePhone',
            modify: true
        },
        {
            id: 9,
            label: <div style={{width: 160}}>发票接收地址</div>,
            value: 'receiveAddress',
            modify: true,
            maxLength: 100,
        },
        {
            id: 10,
            label: <div style={{width: 160}}>邮箱</div>,
            value: 'customerMail',
            modify: true
        },
    ]
    return (
        <div className="content-page">
            {
                resData &&
                <Descriptions title="" bordered column={3} >
                    {
                        list.map((item) => <Descriptions.Item label={item.label} key={item.id} span={3}>
                            {
                                !(opeData && item.modify) &&
                                <div style={{width: 480}}>{resData[item.value]}</div>
                            }
                            {
                                opeData && item.modify &&
                                <Input placeholder='请输入' maxLength={item.maxLength}
                                       onChange={(e) => changeValue(e, item.value)}
                                       value={resData[item.value]} />
                            }
                            </Descriptions.Item>)
                    }
                </Descriptions>
            }
            <Button type="primary" className='c_mt_20'
                    onClick={() => !opeData? setopeData(true): sureModify()}> {!opeData? '修改': '完成'}</Button>
        </div>
    );
}

const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig,
        pageSizeOptions: state.golbal.pageSizeOptions,
        publicInfo: state.golbal.publicInfo,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get_mock: () => {
            dispatch(mock_table())
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(Enterprise)
