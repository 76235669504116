import React, {useEffect, useState} from "react";
import {
    Button,
     Input,
    Modal,
    Table, message
} from 'antd';

// import {TreeDepartment} from "@/components/select";
import {Dispatch} from "redux";
import { mock_table} from "@/store/model/reducers/index";
import {connect} from "react-redux";
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';
// import InputComponent from '@/components/inputBox/index'

import {RouteComponentProps} from "react-router";
import {ajax} from "@/store/midPromise";

const { confirm } = Modal;

interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    typeArr: any,
    get_mock: () => void,
    get_mock2: (data: API.InterfaceData) => void,
}

const { Search } = Input;

const Customer:React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')

    const [resData, setresData] = useState({total: '', page: [], loading: true})

    const [ajaxData, setAjaxData] = useState({
        keyword: '',
        page: 1,
        rows: 10
    })
    const goDetail = (data: any) => {
        // console.log(data)
        const detail_id = data.id || -1
        props.history.push(`/lingGong_B/customer_detail?id=${detail_id}`)
    }
    const getText = (data: any) => {
        return {
            text: data===10? "停用": "启用",
            status: data===10? 20: 10
        }
    }
    const stopAccount = (data: any) => {
        const status = getText(data.cstatus).status
        console.log(data)
        confirm({
            title: `确认要对该方案进行【${getText(data.cstatus).text}】吗？`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk() {
                ajax.put(`/api/Non-eeds/reception/${APINAME}/customer/${data.id}/status`, {status}).then((res: any) => {
                    if(res && res.code === 200) {
                        message.success('成功')
                        init()
                    }
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const formPush = () => {

    }

    const init = () => {
        ajax.get(`/api/Non-eeds/reception/${APINAME}/customer`, {params: ajaxData}).then((res: any) => {
            setresData({...resData, ...{loading: false}})
            if(res && res.code === 200) setresData({...res.data})
        })
    }
    useEffect (() => {
        init()
    }, [ajaxData])

    const columns = [
        {
            title: '签约客户名称',
            dataIndex: 'customerName',
            width: 180,
        },
        {
            title: '主管理员姓名',
            dataIndex: 'adminName',
            width: 120,
        },
        {
            title: '主管理员手机号码',
            dataIndex: 'adminPhone',
            width: 120,
        },
        {
            title: '邮箱账号',
            dataIndex: 'customerMail',
            width: 180,
        },
        {
            title: '客户状态',
            dataIndex: 'cstatus',
            width: 100,
            render: (data: any) => <div>{data===10? '已启用': data===20? '已停用': '--'}</div>,
        },
        {
            title: '操作',
            width: 180,
            fixed: "right" as "right",
            render: (data: any) => <>
                <Button type={'link'} size={'small'} onClick={() =>stopAccount(data)}>{data.cstatus===10? '停用': data.cstatus===20? '启用': '--'}</Button>
                <Button type={'link'} size={'small'} onClick={() => goDetail(data)}>详情</Button>
            </>
        },
    ];
    const layout = {
        labelCol: { span: 8},
        wrapperCol: { span: 18 },
        onFinish: formPush
    };
    const tableOptions = {
        rowKey: 'id',
        scroll:{x: 'max-content', y: 540},
        loading: resData.loading,
        columns: columns,
        dataSource: resData.page,
        onChange: (data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }

    return (
        <div className="content-page">

            <div className='c_flex c_flex_up c_flex_rl' style={{marginBottom: 20}}>
                <div>
                    <Search placeholder="请输入客户名称、银行账号回车搜索" style={{width: 320}}
                            onSearch={value => setAjaxData({...ajaxData, ...{keyword: value, page: 1}})} enterButton />
                </div>
                <div>
                    <Button type={'primary'} onClick={goDetail}>新增开户</Button>
                </div>
            </div>

            <Table {...tableOptions}/>

        </div>
    );
}

const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig,
        pageSizeOptions: state.golbal.pageSizeOptions,
        member_list: state.index.member_list,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get_mock: () => {
            dispatch(mock_table())
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(Customer)
