import React, { useState } from "react";
import { UserLayout } from "@/layout";
import {api_index} from "@/store/model/reducers/index";
import { connect } from "react-redux";
import './index.less';
import {
    // Row, Col,
    Form, Input,
    Button, Tabs, message
} from 'antd';
import {
  UserOutlined,
    LockOutlined,
    WarningOutlined,
    MessageOutlined
    // PhoneOutlined,
    // MessageOutlined
} from '@ant-design/icons';
import { Dispatch } from "redux";
import {RouteComponentProps} from "react-router";
import {public_info} from "@/store/model/reducers/golbal";
import {Link} from "react-router-dom";
import {ajax} from "@/store/midPromise";
const { TabPane } = Tabs;

const windowObj = global.windows || window;

const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

interface Interfaceprops extends RouteComponentProps{
    userLogin: (data: any) => void,
    get_public: () => void,
}

const Index:React.FC<Interfaceprops> = (props) => {
  // console.log(props)

  const [type, setType] = useState('password')
    const [msgdisabled, setmsgdisabled] = useState(false)
    const [msgText, setmsgText] = useState('获取验证码')
    const [loginInfo, setLoginInfo] =useState(false)
    const [loading, setLoading] = useState(false)

    const callback = (key: string) => {
        // console.log(key);
        setType(key)
    }
    const getCode = (t: number) => {
        if(t<=0) {
            setmsgdisabled(false)
            setmsgText(`重新获取`)
            return
        }
        setmsgdisabled(true)
        setmsgText(`${t}s后重新获取`)
        setTimeout(() => getCode(t-1), 1000)
    }
    const getMsg = () => {
      let phone = formLogin.getFieldValue('phone')
      phone = phone && phone.replace(/\s+/g,"");
        if (phone.length !== 11) return
        getCode(60)
      ajax.post('/api/auth/getCode', {phone: phone}).then((res: any) => {
          if(!(res && res.code === 200)) getCode(0)
      })
    }

  const onFinish = (values: any) => {
    // console.log('Success:', values);
      values.phone = values.phone.replace(/\s+/g,"");
    const olddata = {...values}
    const data = windowObj.GLOBAL_LIBS.toFormData(olddata)
      // console.log(olddata, data)
      // props.history.push('/signHelper/member')
      // props.history.push('/signHelper/home')
      setLoading(true)
    props.userLogin({
        data,
      success: (result: any) => {
          // console.log(result)
          setLoading(false)
          message.success('登录成功');
          windowObj.localStorage.setItem("token", result.token);
          windowObj.localStorage.setItem("apiName", result.roleName);
          props.get_public()
          switch (result.roleName) {
              case 'customer': props.history.push('/lingGong_C/bill/upload'); break;
              case 'company': props.history.push('/lingGong_B/bill/manage'); break;
              case 'business': props.history.push('/pass/business/supplier'); break;
              case 'signassistant': props.history.push('/sign_helper/home'); break;
              case 'taxBureau': props.history.push('/flexibleLabor/tax/check'); break;
              case 'splitsalary': window.location.href = '/wage_split'; break;
          }
      },
        overFn: () => {
            setLoading(false)
        }
    })
  };

    const [formLogin] = Form.useForm()
    const getLoginStatus = () => {
        let phone = formLogin.getFieldValue('phone')
        if (phone) phone = phone.replace(/\s+/g,"");
        ajax.post(`/api/auth/first`,  {phone: phone}).then((res: any) => {
            if(res && res.code === 200) setLoginInfo(res.data)
        })
    }
    const formChange = (data: any) => {
        // console.log(data)
        data.phone = data.phone && data.phone.replace(/\s+/g,"");
        if(data.phone && data.phone.length === 11) {
            getLoginStatus()
        }
    }

  return (
    <UserLayout>
      {/*<Row>*/}
        {/*<Col span={8} offset={8}>*/}
          <div className={'layoutLogin'}>
            <Tabs defaultActiveKey={type} onChange={callback}
                  className={'userTabs'}
                  // renderTabBar={tabBar}
            >
              <TabPane tab="账户密码登录" key="password" >
                <Form
                  name="basic"
                  form = {formLogin}
                  initialValues={{ remember: true }}
                  onValuesChange={formChange}
                  onFinish={onFinish}
                >
                  <Form.Item
                    wrapperCol={{span: 24}}
                    name="phone"
                    rules={[{ required: true, message: '请输入账户!' }]}
                  >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="账户" />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{span: 24}}
                    name="password"
                    rules={[{ required: true, message: '请输入密码!' }]}
                  >
                      <Input.Password type="password"
                                      placeholder="密码" prefix={<LockOutlined className="site-form-item-icon" />} />
                  </Form.Item>

                    {
                        loginInfo &&
                        <Form.Item
                            wrapperCol={{span: 24}}
                            name="testCode"
                            rules={[{ required: true, message: '请输入验证码!' }]}
                        >
                            <p><Input type="text" prefix={<MessageOutlined className="site-form-item-icon" /> }
                                      style={{width: '65%'}}
                                      className="code" placeholder="请输入验证码" />
                                <Button type="primary" style={{width: '35%'}} disabled={msgdisabled}
                                        onClick={getMsg}>{msgText}</Button></p>
                        </Form.Item>
                    }


                  <div style={{marginBottom: '20px'}}>
                        <WarningOutlined />
                      <span style={{marginLeft: '10px', fontSize: '12px'}}>你登录的系统在后台有日志记录，若违反公司信息安全规定，违规获取敏感数据将会受到法律的制裁</span>
                  </div>

                  <Form.Item {...tailLayout} className='btnForm'>
                    <Button type="primary" htmlType="submit" className='loginBtn' loading={loading} >
                      登录
                    </Button>
                  </Form.Item>
                </Form>
                <div className='c_text_right'>
                    <Button type="link" size={'small'} className='forgetBtn'> <Link to={'/user/setPassword'} >忘记密码?</Link></Button>
                </div>
              </TabPane>
            {/*  <TabPane tab="手机号登录" key="mobile">*/}
            {/*    <Form*/}
            {/*      name="basic"*/}
            {/*      initialValues={{ remember: true }}*/}
            {/*      onFinish={onRegister}*/}
            {/*    >*/}
            {/*      <Form.Item*/}
            {/*        wrapperCol={{span: 24}}*/}
            {/*        name="mobile"*/}
            {/*        rules={[{ required: true, message: '请输入手机号!' }]}*/}
            {/*      >*/}
            {/*        <Input prefix={<PhoneOutlined className="site-form-item-icon" />} placeholder="手机号" />*/}
            {/*      </Form.Item>*/}
            {/*      <Form.Item*/}
            {/*        wrapperCol={{span: 24}}*/}
            {/*        name="code"*/}
            {/*        rules={[{ required: true, message: '请输入短信验证码!' }]}*/}
            {/*      >*/}
            {/*        <Input*/}
            {/*          prefix={ <MessageOutlined className="site-form-item-icon" />}*/}
            {/*          type="password"*/}
            {/*          placeholder="短信验证码"*/}
            {/*        />*/}
            {/*      </Form.Item>*/}

            {/*      <Form.Item {...tailLayout} className='btnForm'>*/}
            {/*        <Button type="primary" htmlType="submit" className='loginBtn'>*/}
            {/*          登录*/}
            {/*        </Button>*/}
            {/*      </Form.Item>*/}
            {/*    </Form>*/}
            {/*  </TabPane>*/}
            </Tabs>
          </div>
        {/*</Col>*/}
      {/*</Row>*/}

    </UserLayout>
  )
}

const mapStateProps = (state: any) => {
  return {
    // loginInfo: state.user.login,
    // registerInfo: state.user.register,
    // username:state.index.username,
    // password:state.index.password
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    userLogin: (data: any) => {
      // console.log(data)
      dispatch(api_index(data)('login'))
    },
      get_public: () => {
          dispatch(public_info())
      },
  }
};

export default connect(mapStateProps, mapDispatchToProps)(Index)
