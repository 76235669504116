// console.log(process.env.NODE_ENV)
/*
*  api_index(data:API)('name')   data 参考API类型定义，name为action名字
*
*
*
* */

// 暂时无用 begin
export const userLogin = `/api/mock/login`; // mock数据
export const tableMock = `/api/mock/index`; // mock数据
export const userRegister = `/api/mock/register`; // mock数据
export const getTime = `/api/Index/getTimeInfo`  //获取服务器时间
export const feedbackNeeds = `/api/User/userFeedBack`; // 需求反馈接口
export const buryingErrMsg = `/api/front_end/log`; // 错误日志
export const getPublicInfo = `/api/index/getPublicInfo`; // 公共信息
// 暂时无用 over
export const Login=`/api/auth/login`;//登录
export const putFiles = `/api/member/page`; // 导入

export const UserInfo=`/api/auth/getUser`; //公共信息
export const customerInfo=`/api/Non-eeds/reception/customer/getName`; //客户信息


export const tracking_list = `/api/Non-eeds/check/company/traffic`; //快递列表
// 花名册
// export const memberEnd=`/api/member/toEnd`;//停用
// export const memberLeave=`/api/member/toLeave`;//离职
// export const memberResume=`/api/member/toResume`;//复职
// export const addMember=`/api/member/addMember`; //
// export const getMemberList = `/api/member/page`; // 列表
// export const getMemberDateil=`/api/member/selectDetail/001`; //详情

// 组织
// export const getOrganization = `/api/member/page`; // 获取
// export const editOrganization = `/api/member/page`; // 编辑
// export const addOrganization = `/api/member/page`; // 新增
// export const delOrganization = `/api/member/page`; // 删除

const arrApi: any[] = [
    // 登录
    {
        name: 'login',
        methods: 'post',
        api: `/api/auth/login`
    },
    // 导入
    {
        name: 'importFile',
        methods: 'post',
        api: `/api/member/page`
    },

    // 花名册
    {
        name: 'memberEnd',
        methods: 'post',
        api: `/api/member/toEnd`
    },
    {
        name: 'memberLeave',
        methods: 'get',
        api: `/api/member/toLeave`
    },
    {
        name: 'memberResume',
        methods: 'get',
        api: `/api/member/toResume`
    },
    {
        name: 'addMember',
        methods: 'get',
        api: `/api/member/addMember`
    },
    {
        name: 'getMemberList',
        methods: 'get',
        api: `/api/member/page`
    },
    {
        name: 'getMemberDateil',
        methods: 'get',
        api: `/api/member/selectDetail/001`
    },

    // 组织
    {
      name: 'getOrganization',
      methods: 'get',
      api: `/api/member/page`
    },
    {
        name: 'editOrganization',
        methods: 'patch',
        api: `/api/member/page`
    },
    {
        name: 'addOrganization',
        methods: 'post',
        api: `/api/member/add`
    },
    {
        name: 'delOrganization',
        methods: 'delete',
        api: `/api/member/page`
    },
    // 产品方案管理
    { // 灵活用工 / 管理员前台 / 公司端 / 客户管理
        name: 'get_pass_supplier',
        methods: 'get'
    },
    { // 灵活用工 / 管理员前台 / 公司端 / 客户管理
        name: 'get_pass_supplier',
        methods: 'get'
    },
    {  // 修改供应商状态
        name: 'put_pass_supplier',
        methods: 'put'
    },

    { // 产品的列表展示
        name: 'get_pass_product',
        methods: 'get'
    },
    { // 产品的列表 停用 启用
        name: 'put_pass_product_status',
        methods: 'put'
    },
    { // 产品的列表 新增
        name: 'post_pass_product',
        methods: 'post'
    },
    { // 产品的列表 更新
        name: 'put_pass_product',
        methods: 'put'
    },

    { // 税源地的列表 展示
        name: 'get_pass_tax',
        methods: 'get'
    },
    { // 税源地的列表 停用 启用
        name: 'put_pass_tax_status',
        methods: 'put'
    },
    { // 税源地的列表 新增
        name: 'post_pass_tax',
        methods: 'post'
    },
    { // 税源地的列表 更新
        name: 'put_pass_tax',
        methods: 'put'
    },

    { // 交付方案的列表 展示
        name: 'get_pass_deliveryPlan',
        methods: 'get'
    },
    { // 交付方案的列表 停用 启用
        name: 'put_pass_deliveryPlan_status',
        methods: 'put'
    },
    { // 交付方案的列表 新增
        name: 'post_pass_deliveryPlan',
        methods: 'post'
    },
    { // 交付方案的列表 更新
        name: 'put_pass_deliveryPlan',
        methods: 'put'
    },

    // 灵活用工  C端 /api/customer/person  B端 人员管理 /api/com/person   客户管理 /api/customer
    {  // 灵活用工 / 管理员前台 / 公司端 / 人员管理
        name: 'get_LG_C_member_list',
        methods: 'get',
    },
    {  // 灵活用工 / 管理员前台 / 公司端 / 人员管理 新增
        name: 'add_LG_C_member_list',
        methods: 'post',
    },
    {  //  人员管理
        name: 'get_LG_B_member_list',
        methods: 'get',
    },
    {  // 人员管理 新增
        name: 'add_LG_B_member_list',
        methods: 'post',
    },
    {  // 人员管理
        name: 'put_LG_B_member_list',
        methods: 'post',
    },
    {  //  客户管理
        name: 'get_LG_B_customer_list',
        methods: 'get',
    },
    { //  客户管理
        name: 'put_LG_B_customer',
        methods: 'put'
    },
    { // 客户管理
        name: 'add_LG_B_customer',
        methods: 'post'
    },
    { // 客户管理
        name: 'stop_LG_B_customer',
        methods: 'put'
    },
    {  //  客户管理详情
        name: 'get_LG_B_customer_detail',
        methods: 'get',
    }
]

export default arrApi
