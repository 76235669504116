import React, {useEffect, useState} from "react";
import {
    Button,
    Form, Input,
    Modal,
    Tabs, message
} from 'antd';

import {Dispatch} from "redux";
import {api_index, reset_customer_detail} from "@/store/model/reducers/index";
import {connect} from "react-redux";
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';


import {RouteComponentProps} from "react-router";
import {ajax} from "@/store/midPromise";

import PlanComponent from './plan_component'
import BaseComponent from './base_form'
import moment, {Moment} from 'moment';
import {number} from "prop-types";
// import data from "@/statics/js/config/config-data";

const { confirm } = Modal;


interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    typeArr: any,
    api_all: (data: API.InterfaceData) => void,
    get_mock2: (data: API.InterfaceData) => void,
    detailInfo: any,
    reset_data: (data?: any) => void,
}
interface InterfaceinitTem {
    title?: string,
    key: string,
    content: any,
    closable: boolean
}

const { TabPane } = Tabs;

const bankList = [
    {
        name: 'bankAccountName',
        lable: '银行开户名',
        rules: [{max: 50}]
    },
    {
        name: 'bank',
        lable: '开户行',
        rules: [{min: 2,max: 50}]
    },
    {
        name: 'bankCard',
        lable: '银行账号',
        rules: [{min: 2,max: 50, type: 'number'}]
    }
]

let formObj: {planList: any[]} = {planList: [{}]};

const CustomerDetail:React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')
    const id = windowObj.GLOBAL_LIBS.getQuery(props.location.search, 'id')

    const [formbank] = Form.useForm();
    // const [pushAllOnoff, setpushAllOnoff] = useState(true)

    const layout = {
        labelCol: { span: 5},
        wrapperCol: { span: 19 },
    };

    const scrollToTop = () => {
        const Ele = windowObj.document.getElementById('page_content')
        let sTop = Ele.scrollTop
        if (sTop > 0) {
            window.requestAnimationFrame(scrollToTop)
            Ele.scrollTo(0, sTop - sTop / 8)
        }
    }

    let getNowIndex = 0

    const finishBase =(data:any)  => {
        // console.log(panes)
        const name = {bankAccountName: data.customerName}
        formObj = {...formObj, ...data, ...name}
        formbank.setFieldsValue(name)
        props.reset_data({...props.detailInfo, ...name})
        setactiveKey('bank')
    }
    const finishbank = (data:any) => {
        formObj = {...formObj, ...data}
        console.log(formObj)
        setactiveKey('0')
    }
    const finishCase = (da:any, form_id: any) => {
        // console.log(form_id, getNowIndex, panes)
        // formObj.planList.push({...da})
        const newObj = {...props.detailInfo}
        newObj.planList[form_id] = {...da}
        // newObj.planList.push({...da})
        props.reset_data({...newObj})
        if(form_id == getNowIndex){
            confirm({
                title: '提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认提交所有填写的信息吗？',
                onOk() {
                    pushAllForm()
                },
                onCancel() {console.log('Cancel')},
            });
        } else {
            setactiveKey(String(Number(form_id) + 1))
            scrollToTop()
        }
    }
    const pushAllForm = () => {
        const id = windowObj.GLOBAL_LIBS.getQuery(props.location.search, 'id')
        // console.log(formObj)
        // console.log(props.detailInfo)
        if (id<0){
            ajax.post(`/api/Non-eeds/reception/company/customer`, props.detailInfo).then((res: any) => {
                setDelOnoff(true)
                if(res && res.code === 200) {
                    Modal.success({
                        title: '提示',
                        content: '客户创建成功，登录账号为用户手机号码，密码为【123.coM】。',
                        onOk: () => props.history.push('/lingGong_B/customer')
                    })

                }
            })
        } else {
            ajax.put(`/api/Non-eeds/reception/company/customer/${id}`, props.detailInfo).then((res: any) => {
                if(res && res.code === 200) {
                    message.success('成功')
                    props.history.push('/lingGong_B/customer')
                }
            })
        }
    }

    const [activeKey, setactiveKey] = useState<string>('base')

    const [delOnoff, setDelOnoff] = useState(false)

    const [panes, setpanes] = useState<Array<InterfaceinitTem>>([])

    const addCase = () => {
        const arr  = [...(props.detailInfo.planList || [])]
        arr.push({
            account: 0,
            usePlan: '',
            invoiceType: '',
            serverPay: '',
            serverFeeRate: '',
            disServer: '',
            cupPay: '',
            cup: '',
            cupsPay: '',
            cupsCity: '',
            cupsEdu: '',
            cupsLedu: '',
            pupPay: '',
            pup: '',
            pupsPay: '',
            pupsCity: '',
            pupsEdu: '',
            pupsLedu: '',
            perPay: '',
            per: ''
        })
        const newData = { ...props.detailInfo, ...{planList: arr}}
        // console.log(props.detailInfo.planList, arr, newData)
        props.reset_data(newData)
    }
    const removeCase = (targetKey:any) => {
        setactiveKey('base') // 删除之后，定位到客户基本信息
        // 改动 redux 的数据
        const newList = [...props.detailInfo.planList]
        newList.splice(targetKey-0, 1)
        props.reset_data({...props.detailInfo, ...{planList: newList}})
    }
    const changeForm = (data: any, index: number) => {
        // console.log(data)
        formObj = {...props.detailInfo}

        // if (delOnoff) {
        // console.log(index, formObj)
            if ( index>=0) {
                formObj.planList[index] = data
            } else {
                formObj = {...formObj, ...data}
            }
            // console.log(props.detailInfo)
            props.reset_data({...props.detailInfo, ...formObj})
        // }
    }
    const onEdit = (targetKey:any, action:any) => {
        // console.log(targetKey, action)
        if(action === 'add') addCase()
        if(action === 'remove') {
            switch (targetKey) {
                case 'base': ; break;
                case 'bank': ; break;
                default: removeCase(targetKey);
            }
        }
    }

    const bankForm = <>
        <Form {...layout} form={formbank} onFinish={finishbank} onValuesChange={(data: any) => changeForm(data, -1)}>
            {
                bankList.map((item) =>
                    <Form.Item label={item.lable} name={item.name} rules={[{ required: true, ...item.rules}]}>
                        <Input placeholder='请输入'  />
                    </Form.Item>)
            }
            {!delOnoff && <div className='c_text_right'><Button type="primary" onClick={() => formbank.submit()}>下一步</Button></div>}

        </Form>
    </>

    const initTable = (data: any) => {
        const initTem:Array<InterfaceinitTem>  = [
            { title: '客户基本信息', content: <BaseComponent onFinish={finishBase} getChange={changeForm} btn={!delOnoff} />, key: 'base', closable: false },
            { title: '银行账户信息', content: bankForm, key: 'bank', closable: false },
        ];
        if(data && data.planList) {
            data.planList.forEach((item: any, index: number) => {
                initTem.push({
                    content: <PlanComponent onFinish={finishCase} form_id={index} btn={!delOnoff} getChange={changeForm} />,
                    key:  String(index),
                    closable: true
                })
            })
        } else {
            props.reset_data({planList: [{}]})
        }
        setpanes(initTem)
    }

    const changeKey = (data: any) => {
        // console.log(data)
        setactiveKey(data)
    }

    const init = () => {
        props.reset_data()
        if (id<0) {
            initTable({})
            return
        }
        setDelOnoff(true)
        props.api_all({api: `/api/Non-eeds/reception/${APINAME}/customer/detail/${id}`})
    }

    const changeInfo = () => {
        // 获取当前 的 数组长度
        getNowIndex = props.detailInfo && props.detailInfo.planList && props.detailInfo.planList.length - 1
        // 初始化 模版
        initTable(props.detailInfo)
        // 填写数据
        formbank.setFieldsValue(props.detailInfo)
    }
    useEffect (() => {
        init()

        return function cleanup() {
            formbank.resetFields()
        }
    }, [])
    useEffect (() => {
        // console.log('22222')
        // console.log(props.detailInfo)
        changeInfo()
    }, [props.detailInfo])

    return (
        <div className="content-page">
            <Tabs type="editable-card" onTabClick={changeKey} onEdit={onEdit} activeKey={activeKey}
                  tabBarExtraContent={delOnoff && <> <Button type={'primary'} onClick={pushAllForm}>确定修改</Button></>}>
                {panes.map((pane, index) => (
                    <TabPane tab={pane.title || `方案${index-1}信息`} key={pane.key || index-1}
                             closable={pane.closable && delOnoff && panes.length>3} disabled={!delOnoff}>
                        {pane.content}
                    </TabPane>
                ))}
            </Tabs>
        </div>
    );
}

const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig,
        pageSizeOptions: state.golbal.pageSizeOptions,
        detailInfo: state.index.customer_detail
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        api_all: (data: API.InterfaceData) => {
            dispatch(api_index(data)('get_LG_B_customer_detail'))
        },
        reset_data: (data: any) => {
            // console.log(data)
            dispatch(reset_customer_detail(data))
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(CustomerDetail)
