import React from 'react';

import { withRouter} from "react-router-dom";

import {
    DownOutlined
} from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';

import Logo from '../statics/imgs/logo.png';
import {Dispatch} from "redux";
import {connect} from "react-redux";

const style = {
    c_header: {
        height: '65px',
        borderBottom: '1px solid #f0f0f0',
        background: '#fff',
        position: 'absolute' as 'absolute',
        width: '100%',
        zIndex: 22
    }
}

function Header(props: any) {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')

  const menuClick = (data: any) => {
    console.log(data)
    switch (data.key) {
      case '3': loginOut(); break;
    }
  }

  const loginOut = () => {
    props.history.push('/user/login');
    localStorage.setItem('app_role', '')
  }

  const menu = (
    <Menu onClick={menuClick}>
      {/*<Menu.Item key="0">*/}
        {/*权限修改*/}
      {/*</Menu.Item>*/}
      {/*<Menu.Item key="1">*/}
        {/*修改手机号*/}
      {/*</Menu.Item>*/}
      {/*<Menu.Divider />*/}
      <Menu.Item key="3" >
        退出登录
      </Menu.Item>
    </Menu>
  );


    return (
        <header className={'c_flex c_flex_rl c_flex_up'} style={style.c_header}>
            <div style={{marginLeft: '20px'}}>
                <img src={Logo} alt="logo" style={{width: '40px'}} />
                <span className={'px16'} style={{marginLeft: '10px'}}>霖珑工作中心</span>
            </div>
            <div className='c_flex c_flex_rl c_flex_grow' style={{padding: '0 20px 0 100px'}}>
                <div>{ APINAME === 'customer'&& props.customer_info && props.customer_info.customerName}</div>
                <div className='c_flex'>
                    <div></div>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <span className={'mouse_hand'}>{props.publicInfo.username} <DownOutlined /></span>
                    </Dropdown>
                </div>
            </div>
        </header>
    );
}

const mapStateProps = (state: any) => {
    return {
        publicInfo: state.golbal.publicInfo,
        customer_info: state.golbal.customer_info
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(withRouter(Header))
// export default withRouter(Header);
